<template>
    <tr>
        <td class="pl-4 text-left">{{ item.pro_ean}}</td>
        <td class="">
            <ValidationProvider rules="required" v-slot="v">
                <input type="text" class="form-control rounded" :class="{ invalid: v.errors.length > 0 }" v-model="item.pro_descricao" />
            </ValidationProvider>
        </td>
        <td class="text-left">
                <div class="d-flex flex-row align-center">
                    <ValidationProvider rules="required" v-slot="v" slim>
                        <cc-select-v2 :tracker="'value'" :text="'name'" :class="{ invalid: v.errors.length > 0 }" v-model="item.wrap" :value="item.wrap" :options="wraps"></cc-select-v2>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="v" slim>
                        <input type="text" pattern="[0-9]" placeholder="qtd" class="form-control rounded w-15 ml-2 text-center" :class="{ invalid: v.errors.length > 0 }" v-model="item.qtd_emb" />
                    </ValidationProvider>
                </div>
        </td>
        <td class="edit-amount text-center">
            <ValidationProvider rules="required" v-slot="v">
                <cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :class="{ invalid: v.errors.length > 0 }" v-model="item.category" :options="categories"></cc-select-v2>
            </ValidationProvider>
        </td>
        <td class="edit-amount text-center">
            <input type="text" pattern="[0-9]" class="form-control rounded" v-model="item.quantidade" min="0"/>
        </td>
        <td class="edit-amount text-center">
            <input type="text" pattern="[0-9]" class="form-control rounded" v-model="item.estoque" min="0"/>
        </td>
        <td class="edit-amount text-center">
            <input type="text" class="form-control rounded" v-model="item.pro_observacao" />
        </td>
        <td class="text-center actions">
            <i class="fa fa-trash" @click="remove(item)"></i>
        </td>
    </tr>
</template>

<script>

import ProductModel from '@/models/product.model'

export default {
    props: {
        item: [ ProductModel, Object ],
        categories: Array,
        wraps: Array
    },
    watch: {
        'item.quantidade': function(new_val) {
            if(this.item && new_val.match(/[^0-9]/g)) {
                this.item.quantidade = this.item.quantidade.replace(/[^0-9]/g, '')
            }
        },
        'item.qtd_emb': function(new_val) {
            if(this.item && new_val.match(/[^0-9]/g)) {
                this.item.qtd_emb = this.item.qtd_emb.replace(/[^0-9]/g, '')
            }
        },
        'item.estoque': function(new_val) {
            if(this.item && new_val.match(/[^0-9]/g)) {
                this.item.estoque = this.item.estoque.replace(/[^0-9]/g, '')
            }
        }
    },
    methods: {
        remove(item) {
            this.$emit('remove', item);
        }
    }
}
</script>

<style lang="scss">
    @import '@/sass/commons/_variables';

    .invalid {
        border: $brand-danger 1px solid;
        box-shadow: 0px 0px 3px $brand-danger;
    }

</style>
