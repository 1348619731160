<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .edit-amount input {
        margin: auto;
        width: 200px;
        text-align: center;
    }

    .fa-trash {
        cursor: pointer;
        font-size: 20px;
        color: red!important;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .header-title {
        padding-right: 23px;
        text-align: left;
        color: gray;
        font-size: 22px;
    }
    .actions {
        text-align: left;
        color: gray;
        display: flex;
        height: 100%;
        a {
            margin-left: 10px;
        }
        .inner-button-loader {
            width: 100%;
            img {
                height: 1.4rem;
            }
        }
    }
    .fa-trash {
        color: $brand-danger;
    }
    .window {
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        .amount {
            width: 10%;
        }
        .add-prod-icon {
            margin-right: 10px;
            color: $primary-color;
        }
        .slot-1, .slot-2 {
            min-width: 100%;
        }
        .slot-2 {
            transform: translate(120%, 0px);
        }
        input[type="number"] {
            -moz-appearance: textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &.product-amount {
                border-radius: 5px;
                border: 3.1px solid #0000ff4d;
                width: 25%;
                text-align: center;
                margin-left: 23px;
                margin-right: 8px;
                outline: none;
                &.active {
                    background-color: #9fff9f73;
                    color: green;
                }
            }
        }
    }
    .show-print{
        display: none;
    }
</style>
<template>
    <cc-modal :modal="modal" @close="close">
        <div slot="header-actions" class="col-sm-4 not-print actions">
            <cc-button :content="'Imprimir'" :icon="'fa fa-print'" @click="print"/>
            <cc-button :content="'Aplicar Sugestão'" :icon="'fa fa-hand-point-down'" @click="applySugestion"/>
            <cc-button :content="'Salvar'" :icon="'fa fa-save'" @click="save"/>
        </div>

        <div slot="body" class="row" id="modalBodyPrint">
            <div class="col-sm-10 only-print" style="text-align: left;color: gray;">
                <h4 style="padding-right: 23px;text-align: left;color: gray;">Produtos da Cotação: {{quotation}}</h4>
            </div>
            <div class="col-2 pb-4 not-print">
                <cc-button v-if="!new_product" :content="'Adicionar Produtos'" :icon="'fa fa-plus'" :classes="'success'" @click="toggle_my_products" />
                <cc-button v-if="new_product" :content="'Voltar'" :icon="'fa fa-arrow-left'" :classes="'primary'" @click="toggle_my_products" />
            </div>
            <div class="col-12 window">
                <div class="slot-1">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="pl-1"></th>
                                <th class="pl-4">Nome</th>
                                <th class="text-left">EAN</th>
                                <th class="text-left">Embalagem</th>
                                <th class="text-center">Qtd.</th>
                                <th class="text-center">Estoque</th>
                                <th class="text-center">Qtd. Padrão</th>
                                <th class="text-center">Sugestão</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in products" v-bind:key="item.produto_id">
                                <td class="pl-3 text-center">
                                    <i class="fa fa-trash" @click="remove(item)" aria-hidden="true"></i>
                                </td>
                                <td class="pl-4 text-uppercase">{{ item.pro_descricao}}</td>
                                <td class="text-left">{{ item.pro_ean}}</td>
                                <td class="text-left">
                                    {{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}
                                    <i class="far fa-edit" @click="edit_wrap(item)" v-if="item.pro_generico == 0"/>
                                </td>
                                <td class="edit-amount text-center not-print">
                                    <input type="number" class="form-control rounded" v-model="item.quantidade" min="0" @keydown="handle_navigation" ref="amount" data-visible="true"  />
                                </td>
                                <td class="text-center show-print">{{item.quantidade}}</td>
                                <td class="text-center">{{item.estoque || 0}}</td>
                                <td class="text-center">{{item.qtd_padrao || 0}}</td>
                                <td class="text-center">{{item.pro_sugestao || 0}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="slot-2">
                    <table class="table not-print">
                        <thead>
                            <tr>
                                <th colspan="1">
                                    <cc-search :placeholder="'Pesquisar produto'" class="my-1" v-model="product_name_filter" @keyup="load_my_products()" />
                                </th>
                                <th colspan="4" class="text-center">
                                    <i class="fas fa-boxes add-prod-icon"></i>Meus produtos
                                </th>
                            </tr>
                            <tr>
                                <th class="pl-3">Codigo</th>
                                <th class="pl-4">Produto</th>
                                <th>Embalagem</th>
                                <th class="text-center amount">Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, idx) in my_products" :key="'prod-' + product.pro_id" :class="{ active : product.focused == true}">
                                <td class="pl-3">{{ product.pro_ean }}</td>
                                <td>{{product.pro_descricao}}</td>
                                <td>{{ product.pro_generico ? product.pro_unidade_compra : product.pro_embalagem }}</td>
                                <td class="product" :class="{ added : product.added }">
                                    <input
                                        min="0"
                                        type="number"
                                        :data-idx="idx"
                                        ref="new-amount"
                                        class="form-control product-amount text-center product-amount text-center w-75"
                                        v-model.number="product.quantidade"
                                        data-visible="true"
                                        @keydown="handle_my_products_navigation($event, product)"
                                        :key="'qtd' + product.prod_id" />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5">
                                    <div class="footer" :key="'footer'">
                                        <cc-pagination
                                        :key="'pag'"
                                        classes="orange"
                                        :items_by_page_value="items_by_page_value"
                                        :total="total_items"
                                        :page="page"
                                        :page_limit="9"
                                        v-on:change="update_page($event)"
                                        ></cc-pagination>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ProductWrapModal v-if="open_edit_wrap && current_product" :product="current_product" @close="open_edit_wrap = false;getProducts()" />
        </div>
    </cc-modal>
</template>

<script>

import QuotationService from "@/services/QuotationService";
import RequestsService from "@/services/v2/requests.service";
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import { gsap } from "gsap";
import Pagination from "@/components/cliente/base-components/Pagination";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";

export default {
    components: { ProductWrapModal, ccPagination : Pagination },
    mixins: [ elementNavigationmMixin, typingRemoteRequestControlMixin ],
    props: ["quotation"],
    data() {
        return {
        products: [],
        loading: false,
        open_edit_wrap: false,
        new_product: null,
        page: 1,
        my_products: [],
        product_name_filter: null,
        total_items: null,
        items_by_page_value: 30,
        svc: new RequestsService(),
        service: new QuotationService(),
        modal: {
                title: `Produtos de ressuprimento #${this.quotation}`,
                subtitle: 'Gerencie os produtos de ressuprimento',
                icon_title: 'fas fa-boxes',
                unpermitted_message: "Aceite o termo de consentimento para continuar.",
                permitted: true,
                cancel_text: "voltar",
                style: {
                    width: "90%"
                }
            }
        };
    },
    methods: {
        cancelSearch () {
			if (this.cancelSource) {
				this.cancelSource.cancel('Start new search, stop active search');
			}
		},
        toggle_my_products() {
            this.new_product = this.new_product ? null : {};
			if(this.new_product) {
                gsap.fromTo('.slot-1', { x: "0%" }, { x: "-120%", duration: 0.5, ease: "back.out(1)" })
                gsap.fromTo('.slot-2', { x: "120%" }, { x: "-100%", duration: 0.5, ease: "back.out(1)" })
			} else {
                gsap.fromTo('.slot-1', { x: "120%" }, { x: "0%", duration: 0.5, ease: "back.out(1)" })
                gsap.fromTo('.slot-2', { x: "0%" }, { x: "-220%", duration: 0.5, ease: "back.out(1)" })
			}
			this.$forceUpdate()
        },
        add_product(prod) {

            let data = { quantidade: prod.quantidade, prod_id: prod.pro_id, flag_generico: prod.pro_generico }

            if(isNaN(prod.quantidade))return Promise.resolve()

            return this.svc.add_product_to_resupply_request(this.quotation, data).then(() => {
                const notification = {
                    type: 'success',
                    message: 'Produto adicionado com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                this.getProducts()
            })
        },
        handle_navigation($event) {
			this.navigate($event, {
				ref: 'amount'
			})
		},
        handle_my_products_navigation($event, product) {
			this.navigate($event, {
				ref: 'new-amount',
                callback: {
                    function: this.add_product,
                    params: product
                }
			})
		},
        update_page(page) {
			this.page = page;
			this.load_my_products();
		},
        edit_wrap(product) {
            product.embalagem = product.pro_embalagem
            product.descricaoProduto = product.pro_descricao
            this.current_product = product
            this.open_edit_wrap = true
        },
        close() {
            this.$emit("close");
        },
        group_name() {
            return localStorage.getItem("grupo")
        },
        getProducts() {
            return this.service.getProductsResupply(this.quotation).then(response => response.data).then(data => {
                this.products = data.produtos;
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        load_my_products() {

            this.cancelSearch(() => { this.loading = true });
            const params = {
                nomeproduto: this.product_name_filter, page: this.page
            };
            this.service.getProdutosClientePorPagina(params, this.cancelSource?.token).then(data => {
                this.my_products = data.data.produtos.data
                this.loading =false;
                this.page = data.data.produtos.current_page;
                this.totalPaginas = data.data.produtos.last_page;
                this.total_items = data.data.produtos.total;
                this.products.forEach(v => v.expanded = false)
            }).catch(error => {
                this.loading = false
				ErrorHandlerService.handle( error, this.$store );
			});
        },
        applySugestion() {
            this.products.forEach(p => p.quantidade = p.pro_sugestao);
        },
        save() {
            this.loading = true;
            let items = this.products.map(p => { return { "quantidade" : ("" + p.quantidade), "pro_id" : p.pro_id}  });
            var quotationService = new QuotationService();
            quotationService.updateQuotationProductsAmount(this.quotation, items)
            .then(() => {
                this.loading = false;
                this.close();
                this.$swal.fire(
                'Sucesso!',
                'Valores atualizados com sucesso.',
                'success'
                );
            })
        },
        print() {
            setTimeout(() => {

                var conteudoModal = window.document.getElementById("modalBodyPrint").innerHTML;

                var conteudoImpressao =
                "<html>" +
                "<head>" +
                '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css">' +
                '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
                "<title>" +
                "</title>" +
                "<style> \
                    @media print {    \
                        .not-print, .not-print * { \
                            display: none !important; \
                        } \
                        .show-print, .show-print * { \
                            display: block !important; \
                        } \
                    } \
                </style>" +
                "</head>" +
                "<body>" +
                conteudoModal +
                "</body>";

                let name = '_blank';
                let specs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'];
                let replace = true;

                specs = !!specs.length ? specs.join(',') : '';

                var telaImpressao = window.open("", '_blank', ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'], true);

                telaImpressao.document.write(conteudoImpressao);

                setTimeout(() => {
                telaImpressao.document.close();
                telaImpressao.focus();
                telaImpressao.print();
                telaImpressao.close();
                }, 1500);

                this.imprimindo = false;
            }, 500);
        },
        remove(product){
            var quotationService = new QuotationService();
            quotationService.remove_product_ressuply(this.quotation, product.pro_id)
            .then(() => {
                this.getProducts();
                this.$emit("loadRessuply");
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });

        }
    },
    mounted() {
        this.getProducts()
        this.load_my_products()
    }
};
</script>
