<template>
    <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row">
            <cc-loader-full v-show="loading" :text="'Aguarde... Essa operação pode levar alguns minutos.'" />
            <div class="col-6">
                <label for="inputCodigo" class="control-label">Tipo de importação</label>
                <multiselect v-model="importType" @select="checkOrders" placeholder="" open-direction="below" track-by="code" label="label" selected-label="Selecionado" :options="import_methods" :searchable="false" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
            <div class="col-6 store-select" v-if="importType && importType.code !='VAREJOFACIL'">
                <label for="inputCodigo" class="control-label">Selecione a empresa</label>
                <multiselect v-model="storeSelect" open-direction="below" placeholder="" track-by="code" label="label" selected-label="Selecionado" :options="stores" :searchable="false" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
            <div class="col-6 store-select" v-if="importType && importType.code =='VAREJOFACIL'">
                <label for="inputCodigo" class="control-label">Selecione a empresa</label>
                <multiselect v-model="storeSelect" :disabled="all_stores" open-direction="below" placeholder="" track-by="code" label="label" selected-label="Selecionado" :options="stores" :searchable="false" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
                <cc-checkbox v-model="all_stores" :label="'Enviar para todas'" class="pt-3 pl-2" />
            </div>
            <div class="col-12 store-select pt-4" v-if="(storeSelect || all_stores) && importType && importType.code =='VAREJOFACIL'">
                <label for="inputCodigo" class="control-label">Cod. da Cotação</label>
                <cc-input :placeholder="'Cod. da Cotação'" :theme="'theme-green'" class="mt-1" v-model="quotation" />
            </div>
            <div class="pt-3 col-12" v-if="storeSelect && (importType == null || importType.code == 'EXCEL')">
                <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone" :useCustomSlot="true" :options="dropzoneOptions" v-on:vdropzone-success="successResponse" v-on:vdropzone-sending="sendingEvent" >
                    <div class="dropzone-custom-content">
                        <i class="fas fa-cloud-upload-alt"></i>
                        <h5 class="dropzone-custom-title">Solte o arquivo excel para criar a solicitação</h5>
                    </div>
                </vue-dropzone>
            </div>
            <div class="pt-3 col-12" v-if="storeSelect && importType.code == 'VR'">
                <vue-dropzone ref="myVueDropzoneFile" id="dropzoneFile" class="dropzone" :useCustomSlot="true" :options="dropzoneOptionsFile" v-on:vdropzone-success="successResponseFile" v-on:vdropzone-sending="sendingEvent" >
                    <div class="dropzone-custom-content">
                        <i class="fas fa-cloud-upload-alt"></i>
                        <h5 class="dropzone-custom-title">Solte o arquivo para criar a solicitação</h5>
                    </div>
                </vue-dropzone>
            </div>
        </div>
    </cc-modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import QuotationService from "@/services/QuotationService";
import VarejoFacilService from "@/services/VarejoFacilService";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import * as CONSTANTS from "@/constants/constants";

export default {
    components: { vueDropzone: vue2Dropzone },
    data() {
        return {
            modal: {
                title: "importar cotação",
                permitted: true,
                submit: this.importVarejoFacil,
                submit_text: 'Enviar',
                subtitle: 'Aqui você pode importar sua requisição, agilizando a abertura da cotação',
                icon_title: 'fas fa-download',
                style: {
                    width: "70%"
                }
            },
            all_stores: false,
            import_methods: [
                {
                    code: "VAREJOFACIL",
                    label: "Sistema E.R.P"
                },
                {
                    code: "EXCEL",
                    label: "EXCEL"
                },
                {
                    code: "VR",
                    label: "VR Software - Arquivo TXT"
                }
            ],
            stores: [],
            quotations: [],
            quotation: null,
            storeSelect: null,
            lojaSelectImportExcel: null,
            loading: false,
            service: new VarejoFacilService(),
            importType: null,
            dropzoneOptions: {
                url: `${CONSTANTS.API_URL}/importexcelcotacao`,
                timeout: 600000,
                paramName: "file",
                withCredentials: false,
                forceFallback:false,
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                    'Cache-Control': null
                }
            },
            dropzoneOptionsFile: {
                url: `${CONSTANTS.API_URL}/importfilecotacao`,
                timeout: 600000,
                paramName: "file",
                withCredentials: false,
                forceFallback:false,
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token"),
                    'Cache-Control': null
                }
            }
        };
    },
    methods: {
        importVarejoFacil() {
            if(this.quotation) {
                this.loading = true;
                var stores = this.all_stores ? this.stores.map(s => s.code) : [ this.storeSelect.code ]
                var counter = 0
                stores.forEach(store_code => {
                    this.service.importQuotation(this.quotation, store_code)
                    .then(result => result.data.data)
                    .then(result => {
                        counter++

                        if(counter == stores.length) {
                            this.loading = false;
                            this.close();
                            this.$emit("finish");
                        }

                    }).catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store);
                        const notification = {
                            type: 'error',
                            message: 'Ocorreu um erro ao importar cotação! Tente novamente mais tarde.'
                        }
                        this.$store.dispatch('notification/add', notification)
                    })
                })
            }
        },
        checkOrders(type) {
            if(type && type.code=='VAREJOFACIL') {
                this.loading = true;
                this.service.getOpenQuotations()
                .then(result => result.data.data)
                .then(result => {
                    this.loading = false;
                    this.quotations = result.items;
                }).catch(() => this.loading = false)
            }
        },
        close() {
            this.$emit("close");
        },
        successResponse(file, response) {
            this.close();
            this.$refs.myVueDropzone.removeAllFiles();
            this.loading=false;
            this.$emit("finish", response.new_products);
        },
        successResponseFile(file, response) {
            this.close();
            this.$refs.myVueDropzoneFile.removeAllFiles();
            this.loading=false;
            this.$emit("finish", response.new_products);
        },
        sendingEvent(file, xhr, formData) {
                this.loading=true;
                if (this.storeSelect) formData.append("codLoja", this.storeSelect.code);
        },
        getStores() {
            var quotationService = new QuotationService();
            quotationService.getStoresClient()
            .then(response => response.data)
            .then(data => {
                this.stores = data.requisitantes.map(store => {
                    return {
                        code: store.loj_id,
                        label: store.loj_descricao
                    }
                })
                this.setup_store(this.stores)
            })
            .catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        setup_store(stores) {
            if(stores.length == 1) {
                this.storeSelect = { label: stores[0].label, code: stores[0].code  }
            }
        }
        },
        mounted() {
            this.getStores()
        }
    };
</script>
<style lang="scss" scoped>
@import '@/sass/commons/_variables';

/deep/ {

    .dropzone {
        border-style: dashed;
        border-width: 3px;
        border-color: $primary-color;
        .dropzone-custom-title {
            color: $primary-color;
        }
        i {
            font-size: 4rem;
            color: $primary-color;
        }
    }

    .multiselect__single {
        color: black !important;
    }

    .multiselect__option.multiselect__option--highlight {
        background-color: gray !important;
    }

    .modal-body {
        overflow: visible !important;
        border: 4px solid #eaeaea;
        border-radius: 5px;
        padding: 2rem;
    }

    .vdatetime-popup__header {
		background-color: $primary-color !important;
	}
	.vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
		background-color: $primary-color !important;
	}
	.vdatetime-popup__actions__button {
		color: $primary-color !important;
	}
	.vdatetime-time-picker__item--selected {
		color: $primary-color !important;
	}
}
</style>
