<template>
	<!-- <div class="tab-pane fade show active in" id="orders" role="tabpanel" aria-labelledby="order-tab">
        <cc-mobile-note class="mt-3 mb-3" />
        <cc-request-orders-subtab :buscaCotacao="request_id" />
  	</div> -->
    <div class="order-page-container">
        <cc-loader-full v-if="loading"/>
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Pedidos
            </div>
        </div>
        <div class="order-page-title">
            Pedidos 
        </div>
        <div class="order-page-info-containear">
            <Button class="order-page-print-button" :text="'Imprimir'" :iconName="'print'" :action="print_many"/>
            <OrderPartial v-if="order_data" :order_data="order_data" />
        </div>
        <div class="order-page-filter-container">
            <div class="order-page-filter-section1">
                <StandardInputAuto class="desktop" :action="setOrderSearch" :title="'Nº do Pedido'" :width="'18vw'" :type="'text'"/>
                <StandardInputAuto class="desktop" :action="setCotacao" :title="'Nº da Cotação'" :width="'18vw'" :type="'text'"/>
                
                <StandardInputAuto class="tablet" :action="setOrderSearch" :title="'Nº do Pedido'" :width="'45%'" :type="'text'"/>
                <StandardInputAuto class="tablet" :action="setCotacao" :title="'Nº da Cotação'" :width="'45%'" :type="'text'"/>
                <StandardInputAuto class="mobile" :action="setOrderSearch" :title="'Nº do Pedido'" :width="'100%'" :type="'text'"/>
                <StandardInputAuto class="mobile" :action="setCotacao" :title="'Nº da Cotação'" :width="'100%'" :type="'text'"/>
            </div>
            <div class="order-page-filter-section2">
                <StandardInputListFiltered class="desktop" :action="setCompany" :title="'Empresa'" :width="'22vw'" :selectWidth="'21.6vw'" :propPlaceholder="'Selecione a empresa'" :list="arraySelectLoja"/>
                <StandardInputListFiltered class="desktop" :action="setSuplier" :title="'Fornecedor'" :width="'25vw'" :selectWidth="'24.6vw'" :propPlaceholder="'Selecione o fornecedor'" :list="arraySelectFornecedor"/>
                <StandardInputListFiltered class="desktop" :action="setStatus" :title="'Status'" :width="'19vw'" :selectWidth="'18.6vw'" :propPlaceholder="'Selecione o status'" :list="statuses"/>
                
                <StandardInputListFiltered class="tablet" :action="setCompany" :title="'Empresa'" :width="'28%'" :selectWidth="'27.6%'" :propPlaceholder="'Selecione a empresa'" :list="arraySelectLoja"/>
                <StandardInputListFiltered class="tablet" :action="setSuplier" :title="'Fornecedor'" :width="'34%'" :selectWidth="'33.6%'" :propPlaceholder="'Selecione o fornecedor'" :list="arraySelectFornecedor"/>
                <StandardInputListFiltered class="tablet" :action="setStatus" :title="'Status'" :width="'28%'" :selectWidth="'27.6%'" :propPlaceholder="'Selecione o status'" :list="statuses"/>

                <StandardInputListFiltered class="mobile" :action="setCompany" :title="'Empresa'" :width="'100%'" :selectWidth="'98%'" :propPlaceholder="'Selecione a empresa'" :list="arraySelectLoja"/>
                <StandardInputListFiltered class="mobile" :action="setSuplier" :title="'Fornecedor'" :width="'100%'" :selectWidth="'98%'" :propPlaceholder="'Selecione o fornecedor'" :list="arraySelectFornecedor"/>
                <StandardInputListFiltered class="mobile" :action="setStatus" :title="'Status'" :width="'100%'" :selectWidth="'98%'" :propPlaceholder="'Selecione o status'" :list="statuses"/>
            </div>
        </div>
        <div class="selected-options-containear">
            <div class="selected-option" v-if="buscaPedido">
                <div>{{buscaPedido}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => buscaPedido = undefined">close</span>
            </div>
            <div class="selected-option" v-if="buscaCotacao">
                <div>{{buscaCotacao}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => buscaCotacao = undefined">close</span>
            </div>
            <div class="selected-option" v-if="selectedLoja">
                <div>{{selectedLoja.loj_nomeFantasia}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => selectedLoja = ''">close</span>
            </div>
            <div class="selected-option" v-if="selectedFornecedor">
                <div>{{selectedFornecedor.for_nome}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => selectedFornecedor = ''">close</span>
            </div>
            <div class="selected-option" v-if="filters.status">
                <div>{{filters.status}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => filters = {}">close</span>
            </div>
        </div>
        <div class="order-page-table-container">
            <div class="order-page-table-size">
                <div v-if="!loading">
                    <div class="order-page-table-header">
                        <span v-if="isAllCheck" v-on:click="checkAll(false)" class="material-icons-outlined checkbox-width">check_box</span>
                        <span v-else v-on:click="checkAll(true)" class="material-icons-outlined checkbox-width">check_box_outline_blank</span>
                        <div class="id-width">ID</div>
                        <div class="store-width">Loja</div>
                        <div class="date-width">Data</div>
                        <div class="price-width">Cotação</div>
                        <div class="seller-width">Vendedor</div>
                        <div class="value-width">Valor(R$)</div>
                        <div class="status-width">Status</div>
                        <div class="confirmationdate-width confirmationdate-header-text">Data de Confirmação</div>
                        <div class="suplier-width">Fornecedor</div>
                        <div class="review-width">Avaliação</div>
                        <div class="actions-width">Ações</div>
                    </div>
                    <div class="order-page-table" v-for="(data,index) in arrayPedido" :key="index">
                        <span v-if="data.isCheck" v-on:click="togleCheck(data)" class="material-icons-outlined checkbox-width order-page-table-separator">check_box</span>
                        <span v-else v-on:click="togleCheck(data)" class="material-icons-outlined checkbox-width order-page-table-separator">check_box_outline_blank</span>
                        <div class="id-width order-page-table-separator">{{ data.id_pedido }}</div>
                        <div class="store-width order-page-table-separator">{{ data.loj_descricao }}</div>
                        <div class="date-width order-page-table-separator">{{ getDate(data.data_geracao) }}</div>
                        <div class="price-width order-page-table-separator">{{ data.id_cotacao }}</div>
                        <div class="seller-width order-page-table-separator">{{ data.vendedor }}</div>
                        <div class="value-width order-page-table-separator">{{ data.valor_pedido | currency }}</div>
                        <div class="status-width order-page-table-separator">
                            <span v-if="data.status === 'C'" title="Confirmado pelo fornecedor" style="color: #30AA4C; cursor: default" class="material-icons-round">check_circle</span>
                            <span v-else title="Aguardando confirmação do fornecedor" class="material-icons" style="color: #CD2A2A; cursor: default">cancel</span>
                            <span v-if="data.status === 'C'">
                                <span v-if="data.status_recebido" title="Pedido recebido!" style="color: #30AA4C; cursor: default" class="material-icons">local_shipping</span>
                                <span v-else title="Pedido ainda não foi recebido!" style="color: #CD2A2A; cursor: default" class="material-icons">local_shipping</span>
                            </span>
                        </div>
                        <div class="confirmationdate-width order-page-table-separator">{{ getDate(data.data_confirmacao) }}</div>
                        <div class="suplier-width order-page-table-separator">{{ data.fornecedor ? data.fornecedor.for_nome : '--' }}</div>
                        <div class="review-width order-page-table-separator">{{ data.nota ? data.nota : '--' }}</div>
                        <div class="actions-width">
                            <span class="material-icons" @click="print(data)" style="font-size: 1.5em; color: #CD2A2A; cursor: pointer">picture_as_pdf</span>
                            <span v-on:click="show(index)" class="material-icons" style="font-size: 1.5em; color: #FFCA2B; cursor: pointer">star</span>
                            <router-link v-if="data.order_id"
                                :to="{ name: 'subrequest',
                                    params: {
                                        return_to: 'monitors-page',
                                        id: data.id_cotacao,
                                        seller_id: data.usu_id,
                                        store_id: data.id_loja,
                                        tab: 'pedidos',
                                        provider_id: data.for_id },
                                    }" tag="span">
                                <span class="material-icons" style="font-size: 1.5em; color: #17A2B8; cursor: pointer">error_outline</span>
                            </router-link>
                            <router-link v-else
                                :to="{
                                    name: 'lancamento-cliente-monitores-vencedores',
                                    params: {
                                        backRouteName: 'monitors-page',
                                        id: data.id_cotacao,
                                        sellerid: data.usu_id,
                                        sid: data.id_loja,
                                        providerid: data.for_id },
                                    query: { backRoute: 2 } }" tag="span">
                                <span class="material-icons" style="font-size: 1.5em; color: #17A2B8; cursor: pointer">error_outline</span>
                            </router-link>
                            <span v-if="data.status === 'C' && !data.status_recebido" @click="confirm_delivery(data, index)" class="material-icons" style="font-size: 1.5em; color: #30AA4C; cursor: pointer">done_all</span>
                            <span v-if="data.status === 'P' && !data.status_recebido" @click="confirm_delivery_status_p(data)" class="material-icons" style="font-size: 1.5em; color: #30AA4C; cursor: pointer">done_all</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 5vh">
                <cc-pagination
                    classes="orange justify-end"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="paginaAtual"
                    v-on:change="updatePage($event)"
                ></cc-pagination>
            </div>
        </div>

        <modal name="hello-world" classes="v--modal modal-avaliar " styles="margin: 30px auto;left: 0px !important;position: inherit !important;" width="850px" height="600px" :clickToClose="false">
            <div class="container modalBorder">
                <div class="d-flex justify-content-end">
                    <h1 class="closeModal p-1" @click="hide">X</h1>
                </div>
                <h4 class="modalTitlulo pb-3 pt-2 text-center">
                    Como foi sua experiência com
                    <span class="modalTituloNome">{{indexArray}}</span> ?
                </h4>
                <div class="d-flex justify-content-center">
                <star-rating
                    @rating-selected="modalClick"
                    v-model="rating"
                    :star-size="50"
                    :show-rating="false"
                ></star-rating>
                </div>
                <div>
                <div class="d-flex justify-content-center py-3">
                    <h1 class="notaVendedor">{{notaVendedor}}</h1>
                </div>
                <div class="d-flex justify-content-center py-2">
                    <h5 class="subTitulo">Escolha um ou mais problemas que você encontrou:</h5>
                </div>
                <div class="d-flex justify-content-center py-2">
                    <form class="formModal">
                    <div class="form-group" v-for="(data,key) in arrayCheckbox" :key="data.id">
                        <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            :name="data.descricao"
                            :id="data.id"
                            :checked="checkbox[key] == data.id"
                            v-model="checkbox[key]"
                        />
                        <label class="custom-control-label" :for="data.id">{{data.descricao}}</label>
                        </div>
                    </div>
                    </form>
                </div>

                <div class="d-flex justify-content-center pb-2">
                    <h5 class="subTitulo">Sua avaliação é feita de forma anônima.</h5>
                </div>
                <div class="d-flex justify-content-center pt-2">
                    <button class="btnFeedback" @click="postAvaliacaoPedido">Enviar Feedback</button>
                </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Button from "@/components/ui/buttons/StandardButton.vue"
import OrderService from "@/services/OrderService";
import * as CONSTANTS from "@/constants/constants";
import DirectOrdersSubTab from './_direct_orders.subtab'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import QuotationService from "@/services/OrderService";
import RequestOrdersSubTab from './_request_orders.subtab'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import OrderPartial from "@/components/partials/_print_order.partial";
import SellersService from "@/services/v2/sellers.service";
import Paginacao from "@/components/cliente/base-components/Pagination";
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ loaderMixin, perm_mixin ],
    props: {
        request_id: {
            type: Number
        }
    },
    components: {
        'cc-direct-orders-subtab' : DirectOrdersSubTab,
        'cc-request-orders-subtab' : RequestOrdersSubTab,
        Button,
        StandardInputAuto,
        OrderPartial,
        'cc-pagination': Paginacao,
        StandardInputListFiltered
    },
    data() {
        return {
            page: 1,
            showPagination: false,
            orders: [],
            CONSTANTS: CONSTANTS,
            items_by_page_value: 5,
            service: new OrderService(),
            orderService: new QuotationService(),
            svc: new SellersService(),
            is_loading: true,
            is_loading_pdf: false,
            selectPedidos: [],
            arrayPedido: [],
            order_data: null,
            current_order: null,
            buscaPedido: null,
            buscaCotacao: null,
            arraySelectLoja: [],
            filters: {},
            arraySelectFornecedor: [],
            selectedLoja: "",
            selectedFornecedor: "",
            statuses: [
                { value: 'P', text: 'Pendente' },
                { value: 'C', text: 'Confirmado' },
                { value: 'D', text: 'Entregue' },
                { value: 'ND', text: 'Não Entregue' }
            ],
            isAllCheck: false,
            indexArray: "",
            indexIdPedido: null,
            rating: 0,
            checkbox: [],
            arrayCheckbox: null,
            notaVendedor: null,
            total_items: 1000,
            paginaAtual: 1,
            loading: true
        };
    },
    methods: {
        checkAll (value) {
            this.arrayPedido.forEach(element => element.isCheck = value )
            this.isAllCheck = value
        },
        togleCheck (data) {
            data.isCheck = !data.isCheck
            if (!data.isCheck) this.isAllCheck = false
            this.$forceUpdate()
        },
        getDate (date) {
            if (date) { return moment(String(date)).format("DD/MM/YYYY")}
            else { return '--'}
        },
        reloadOrders () {
            if (!this.loading) {
                this.loading = true
                setTimeout(() => {
                    this.getListaPedidos(this.page)
                }, 2000)
            }
        },
        setCotacao (text) { 
            this.buscaCotacao = text 
            this.reloadOrders()
        },
        setOrderSearch (text) {
            this.buscaPedido = text
            this.reloadOrders()
        },
        setCompany (value) {
            this.selectedLoja = value
            this.getListaPedidos(this.page)
        },
        setSuplier (value) {
            this.selectedFornecedor = value
            this.getListaPedidos(this.page)
        },
        setStatus (value) {
            this.filters.status = value
            this.getListaPedidos(this.page)
        },
        testMethod () { console.log('foi') },
        getTotal(order) {
            return order.itens.reduce((x, { preco_embalagem, quantidade }) => x + (preco_embalagem*quantidade), 0);
        },
        translateStatus(status) {
            switch(status) {
                case "AGUARDANDO_CONFIRMACAO":
                    return "AGUARDANDO CONFIRMACAO";
                case "CONFIRMADO":
                    return "CONFIRMADO";
                default:
                    return "Não identificado!"
            }
        },
        paginate() {
            this.page = this.page + 1;
            this.getDirectSaleOrders();
        },
        confirm_delivery(order, index) {
            this.confirm_action({
                message: "Confirma recebimento do pedido?",
                deny_text: "Com divergência!",
                cancel_buttom_color: '#4D4F5C',
				callback: (result) => {
                    this.svc.confirm_delivery(order.id_pedido).then(res => {
                        order.status_recebido = true
                    })
                },
                deny_callback: () => {
                    this.svc.confirm_delivery(order.id_pedido).then(res => {
                        order.status_recebido = true
                        this.show(index)
                    })
                }
			})
        },
        confirm_delivery_status_p(order) {
            this.confirm_action({
                message: "Confirma recebimento do pedido?",
                cancel_buttom_color: '#4D4F5C',
				callback: (result) => {
                    this.svc.confirm_delivery_status_p(order.id_pedido).then(res => {
                        order.status_recebido = true
                        this.reloadOrders()
                    })
                }
			})
        },
        updatePage(new_page) { this.getListaPedidos(new_page); },
        postAvaliacaoPedido() {
            let arrayCheck = [];
            this.checkbox.forEach((data, index) => {
                if (data) {
                arrayCheck.push(this.arrayCheckbox[index].id);
                }
            });
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.post(`${CONSTANTS.API_URL}/atualizaravaliacaopedido`, {
                id_pedido: this.indexIdPedido,
                nota: this.rating,
                comentarios: arrayCheck,
                observacao: " "
            }, config).then(() => {
            this.hide();
            this.getListaPedidos(this.paginaAtual);
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        hide() {
            this.$modal.hide("hello-world");
            this.rating = 0;
            this.select = "";
            this.checkbox = [];
        },
        modalClick() {
            if (this.rating === 1) {
                this.notaVendedor = "Vendedor péssimo poderia melhorar.";
            }
            if (this.rating === 2) {
                this.notaVendedor = "Vendedor ruim poderia melhorar.";
            }
            if (this.rating === 3) {
                this.notaVendedor = "Vendendo regular poderia melhorar.";
            }
            if (this.rating === 4) {
                this.notaVendedor = "Vendedor bom mas poderia melhorar.";
            }
            if (this.rating === 5) {
                this.notaVendedor = "Vendedor Excelente!";
            }
        },
        show(index) {
            this.$modal.show("hello-world");
            this.indexArray = this.arrayPedido[index].vendedor;
            this.indexIdPedido = this.arrayPedido[index].id_pedido;
            this.rating = this.arrayPedido[index].nota;
            this.checkbox = this.arrayCheckbox.map(opts => {
                let opt = this.arrayPedido[index].avaliacao.find(item => item.id == opts.id)
                return opt ? true : false
            })
        },
        getArrayPedido() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            }
            axios.get(`${CONSTANTS.API_URL}/listarcomentariospedido`, config)
            .then(data => {
                this.arrayCheckbox = data.data.comentarios;
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        getDirectSaleOrders() {
            this.is_loading = true;
            this.service.getDirectSaleOrders({ page: this.page, page_size: this.items_by_page_value })
            .then(response => response.data)
            .then(data => {
                this.is_loading = false;
                this.orders = this.orders.concat(data.data);
                this.showPagination = data.next_page_url != null;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });;
        },
        print(order) {

            this.current_order = order

            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                [ order.id_pedido ], [ order.id_cotacao ], [ order.id_loja ], [ order.usu_id ]
            ]

            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };

            this.orderService.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                Vue.nextTick(() => {
                    window.document.title = `pedido-${order.id_pedido}.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        print_many() {
            this.is_loading_pdf = true;

            this.selectPedidos = this.arrayPedido.filter(order => order.isCheck)
            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                this.selectPedidos.map(item => item.id_pedido),
                this.selectPedidos.map(item => item.id_cotacao),
                this.selectPedidos.map(item => item.id_loja),
                this.selectPedidos.map(item => item.usu_id)
            ]
            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };

            this.orderService.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                Vue.nextTick(() => {
                    window.document.title = `pedidos.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        getLojas() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            return axios.get(`${CONSTANTS.API_URL}/getrequisitantescliente`, config)
            .then(data => {
                data.data.requisitantes.forEach(element => {
                    this.arraySelectLoja.push({
                        text: element.loj_nomeFantasia,
                        value: element
                    })
                })
                if(!this.can('REQUEST_ORDER', 'READ_ALL')) {
                    this.selectedLoja = this.arraySelectLoja[0].value
                }
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getListaPedidos(page) {
            this.loading = true;
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    idPedido: this.buscaPedido,
                    numerocotacao: this.buscaCotacao,
                    fornecedor: this.selectedFornecedor? this.selectedFornecedor.for_id : null,
                    loja: this.selectedLoja ? this.selectedLoja.loj_id : null,
                    singleorder: false,
                    status: this.filters.status ? this.filters.status : null
                }
            };
            console.log(page)
            let url = `${CONSTANTS.API_URL}/listarpedidos?page=${page}`;
            axios.get(url, config).then(data => {
                this.arrayPedido = data.data.pedidos.data;
                this.loading = false;
                this.paginaAtual = data.data.pedidos.current_page;
                this.totalPaginas = data.data.pedidos.last_page;
                this.total_items = data.data.pedidos.total;
            }).catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getFornecedor() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        axios
            .get(`${CONSTANTS.API_URL}/getfornecedorescliente`, config)
            .then(data => {
            data.data.fornecedores.forEach(element => {
                this.arraySelectFornecedor.push({
                    text: element.for_nome,
                    value: element
                })
            });
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
    },
    watch: {
        items_by_page_value: function() {
            this.orders = [];
            this.getDirectSaleOrders();
        }

    },
    mounted() {
        this.getLojas().then(() => this.getListaPedidos(1));
        this.getArrayPedido();
        this.getDirectSaleOrders();
        this.buscaCotacao = this.request_id
        this.getFornecedor();
        window.scrollTo(0,0)
    }
};
</script>

<style lang="scss" scoped>
@import "@/sass/commons/_variables";
.order-page-container{
    font-size: 1vw;
}
.page-route-select{
    display: flex;
    align-items: center;
}
.page-route-select-text{
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.page-route-select-icon{
    color:  var(--primary-color);
    margin-right: 20px;
}
.order-page-print-button{
    width: 12%;
}
.order-page-title{
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
}
.order-page-info-containear{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 3vh;
}
.order-page-filter-container{
    display: flex;
    margin-top: 2vh;
}
.order-page-table-header{
    margin-top: 4vh;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    padding: 0.7vh 0;
    display: flex;
    align-items: center;
}
.checkbox-width{
    width: 3.5%; text-align: center;
    color:  var(--primary-color);
    cursor: pointer;
}
.id-width{width: 5%; text-align: center;}
.store-width{width: 7%; text-align: center;}
.date-width{width: 7%; text-align: center;}
.price-width{width: 5%; text-align: center;}
.seller-width{flex: 1; text-align: center;}
.value-width{width: 6%; text-align: center;}
.status-width{width: 6%; text-align: center;}
.confirmationdate-width{
    width: 11%;
    text-align: center;
    
}
.confirmationdate-header-text{ font-size: 0.8em; }
.suplier-width{width: 14%; text-align: center;}
.review-width{width: 6%; text-align: center;}
.actions-width{
    width: 9%;
    display: flex;
    justify-content: center;
}
.order-page-table{
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    align-items: center;
    min-height: 6vh;
}
.order-page-table-separator{
    border-right: 0.5px solid #DDE5E9;
}
.order-page-table-container{
    overflow-x: auto;
}
.order-page-table-size{
    min-width: 1150px;
}
.order-page-filter-section1{
    display: flex;
    justify-content: space-between;
    width: 34%;
    margin-right: 3vw;
}
.selected-options-containear{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1.5vw;
  min-height: 7vh;
}
.selected-option{
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 5%;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-top: 1vh;
}
.order-page-filter-section2{
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.tablet{ display: none; }
.mobile{ display: none; }
@media only screen and (max-width: 1150px) {
    .order-page-filter-container{
        flex-direction: column;
    }
    .desktop{ display: none; }
    .tablet{ display: block; }
    .order-page-filter-section1{
        width: 60%;
        margin-bottom: 2vh;
    }
    .order-page-container{
        font-size: 1.25vw;
    }
    .order-page-table-container{
        font-size: 12px;
    }
}
@media only screen and (max-width: 800px) {
    .order-page-filter-section1{
        flex-direction: column;
        margin-bottom: 0;
    }
    .order-page-filter-section2{
        flex-direction: column;
    }
    .tablet{ display: none; }
    .mobile{
        display: block;
        margin-bottom: 2vh;
    }
    .order-page-filter-section1{ width: 100%; }
    .order-page-info-containear{
        flex-direction: column; 
        align-items: flex-start;
    }
    .order-page-print-button{
        width: 40%;
        margin-top: 2vh;
    }
    .order-page-container{
        font-size: 1.5vw;
    }
}
@media only screen and (max-width: 700px) {.order-page-container{ font-size: 1.75vw; }}
@media only screen and (max-width: 600px) {.order-page-container{ font-size: 2vw; }}
@media only screen and (max-width: 500px) {.order-page-container{ font-size: 2.5vw; }}
@media only screen and (max-width: 400px) {.order-page-container{ font-size: 2.75vw; }}
@media only screen and (max-width: 300px) {.order-page-container{ font-size: 3vw; }}
@media only screen and (max-width: 200px) {.order-page-container{ font-size: 3.25vw; }}
</style>
