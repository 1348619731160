<template>
    <div class="tab-pane fade show active in mt-3" role="tabpanel">
        <div class="container-fluid mb-3">
            <div class="row">
                 <cc-mobile-note />
                 <div  class="col-7 d-flex align-items-center buttons">
                    <cc-button :disabled="listMobileSelect.length == 0" class="ml-2" :content="'Consolidar'" :icon="'fa fa-share-square'" @click="consolidateListMobile()" />
                    <cc-button :disabled="listMobileSelect.length == 0" class="ml-2" :content="'Visualizar Produtos'" :icon="'fa fa-search'" @click="productListMobile()" />
                    <cc-button :disabled="listMobileSelect.length == 0" class="ml-2 danger-outline" :classes="'danger-outline'" :content="'Deletar Solicitação'" :icon="'fa fa-trash'" @click="deleteListMobile()" /> 
                 </div> 
            </div>
        </div>
        
        <div class="p-4 shadow">

            <cc-loader v-show="is_loading_list" />
            
            <no-data-available v-if="listMobileList.length == 0 && !is_loading_list" :text="'Sua lista está vazia, para criar novas listas acesse nosso aplicativo mobile'" />

            <table class="table" :class="{ hidden : (is_loading_list || listMobileList.length == 0) }">
                <thead>
                    <tr>
                        <th>
                            <div class="d-flex align-items-center h-100">
                                <input class="custom-checkbox" :id="'checkbox-all-list'" type="checkbox" @change="checkAllListMobile" />
                                <label class="check-title" :for="'checkbox-all-list'"></label>
                            </div>
                        </th>
                        <th>Cod</th>
                        <th>Data Envio</th>
                        <th>Descrição</th>
                        <th>Produtos</th>
                        <th>Empresa</th>
                        <th>Usuário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listMobileList" :key="item.lista_id">
                        <td>
                            <div class="d-flex align-items-center h-100">
                                <input v-model="listMobileSelect" class="custom-checkbox" :id="'checkbox-'+item.lista_id" type="checkbox" :value="item.lista_id" />
                                <label :for="'checkbox-'+item.lista_id"></label>
                            </div>
                        </td>
                        <td>{{ item.lista_id}}</td>
                        <td>{{ item.data_envio | formatDate}}</td>
                        <td>{{ item.lista_descricao}}</td>
                        <td>{{ item.qtd_produtos}}</td>
                        <td>{{ item.loj_descricao}}</td>
                        <td>{{ item.usu_nome}}</td>
                    </tr>                    
                </tbody>
            </table>
        </div>
        <ModalProductsListMobile id="modal" :listMobile="listMobileSelect" :nameListMobile="nameListMobile" v-if="showProductListMobile" @close="closeProductListMobile()" />
    </div>
</template>

<script>
import ModalSendListMobile from "@/components/cliente/monitors/ModalSendListMobile";
import ModalProductsListMobile from "@/components/cliente/monitors/ModalProductsListMobile";
import QuotationService from '../../../services/QuotationService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import ErrorHandlerService from "@/services/ErrorHandlerService";

export default {
    mixins: [ loaderMixin ],
    components: {
        ModalSendListMobile,
        ModalProductsListMobile
    },
    data() {
        return {
            service: new QuotationService(),
            listMobileSelect: [],
            listMobileList: [],
            showSendListMobile: false,
            showProductListMobile: false,
            is_loading_list: false,
            nameListMobile: []
        }
    },
    methods: {
        deleteListMobile: function() {
            if (this.listMobileSelect.length > 0) {
                this.$swal.fire({
                    title: "Tem certeza que deseja deletar a lista?",
                    text: "Sua solicitação será apagada.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo deletar!"
                })
                .then(result => {
                    if (result.value) {
                        this.service.deleteListMobile(this.listMobileSelect)
                        .then(response => response.data)
                        .then(data => {
                            this.listMobile();

                            this.$swal.fire(
                                "Deletado!",
                                "Sua lista foi apagada com sucesso.",
                                "success"
                            );
                        })
                        .catch(error => {
                            this.$swal.fire(
                                "Erro!",
                                "Ocorreu um erro ao realizar a ação.",
                                "error"
                            );
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                });
            } else {
                this.$swal.fire("Ops!", "Selecione uma lista!", "warning");
            }
        },
        closeSendListMobile: function() {
            this.showSendListMobile = false;
        },
        closeProductListMobile: function() {
            this.showProductListMobile = false;
        },
        consolidateListMobile() {

            this.confirm_action({
                message: 'Confirma envio da lista para ressuprimento?',
                callback: () => {
                    this.loading = true;
                    var quotationService = new QuotationService();
                    quotationService.consolidateListMobile({
                        listas: this.listMobileSelect,
                        tipo: "S"
                    }).then(response => response.data).then(data => {
                        this.loading = false;
                        this.consolidatedSuccess()
                        this.listMobile()
                    }).catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store);
                    });
                }
            })
        },
        consolidatedSuccess() {
            this.$swal
            .fire("Publicado!", "Sua lista foi publicada com sucesso.", "success")
            .then(() => (this.tab = "RESUPLY"));
        },
        checkAllListMobile(e) {
            this.listMobileSelect = [];
            if (e.target.checked) {
                this.listMobileList.forEach(element => {
                    this.listMobileSelect.push(element.lista_id);
                });
            }
        },
        sendListMobile: function() {
            if (this.listMobileSelect.length > 0) {
                this.showSendListMobile = true;
            } else {
                this.$swal.fire("Erro!", "Selecionar uma(s) lista(s).", "error");
            }
        },
        productListMobile: function() {
            if (this.listMobileSelect.length > 0) {     
                this.listMobileSelect.forEach(element => { 
                    let mobile = this.listMobileList.filter(list =>{
                        return list.lista_id == element;
                    });
                    this.nameListMobile.push(mobile[0].lista_descricao);
                });
                this.showProductListMobile = true;
            } else {
                this.$swal.fire("Erro!", "Selecionar uma(s) lista(s).", "error");
            }
        },
        listMobile(){
            this.is_loading_list = true;

            this.service.getListMobile(null)
            .then(response => response.data)
            .then(data => {
                this.listMobileList = data.lista
                this.listMobileList.sort((a, b) => {
                    let pos2 = a.lista_id
                    let pos1 = b.lista_id
                    if (pos1 > pos2) return 1
                    if (pos2 > pos1) return -1
                    return 0
                })
                this.is_loading_list = false;
            })
            .catch(error => {
                this.is_loading_list = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
    },
    created() {
        this.listMobile();
    }
}
</script>

<style>

</style>