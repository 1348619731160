<!-- <style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    /deep/ {

        #datetime-wrp {
            width: fit-content;
            .vdatetime {
                width: 16rem;
                display: inline-block;
            }
            .picker {
                display: inline-flex;
                height: 37px;
                background: $blue1;
                width: 29px;
                align-items: center;
                justify-content: center;
                margin: 0;
                cursor: pointer;
                i {
                    color:white;
                }
            }
            .control-label {
                display: block;
            }
        }
        .bootstrap-datetimepicker-widget {
            td {
                .active,
                a.btn {
                    color: #f39c12;
                }
            }

            .day.active {
                background-color: #f39c12;
            }
        }
        .vdatetime-input {
            font-family:"Source Sans Pro", sans-serif;
            font-size: 14px;
            width: 100%;
            height: 100% !important;
            border: none;
            padding: 0;
            margin: 0;
        }
        .control-label {
            padding-left: 0;
            font-size: 1rem;
            font-family: $font-default;
        }
        .vdatetime-popup__header {
            background-color: $primary-color !important;
        }
        .vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
            background-color: $primary-color !important;
        }
        .vdatetime-popup__actions__button {
            color: $primary-color !important;
        }
        .vdatetime-time-picker__item--selected {
            color: $primary-color !important;
        }
    }
</style> -->
<!-- <template>
    <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row">
            <div class="col-sm-12 d-flex justify-content-center">
                <cc-loader v-show="loading" :text="'Salvando..'" class="default" />
                <div class="form-group" id="datetime-wrp" v-if="!loading">
                    <label for="inputCodigo" class="control-label">Nova Data de expiração</label>
                    <datetime v-model="dateExpiration" auto :min-datetime="min_date" :minute-step=10 type="datetime" class="form-control postpone-exp-date" input-id="postpone-exp-date"></datetime>
                    <span class="picker rounded-right" @click="open_datepicker">
						<i class="fa fa-calendar"></i>
					</span>
                </div>
            </div>
        </div>
    </cc-modal>
</template> -->

<template>
    <div class="modal-mask">
        <cc-loader-full v-if="loading"/>
        <div class="modal-content-container">
            <div class="modal-date-header">
                Prazo de Digitação
                <span class="material-icons modal-date-close-icon" v-on:click="close()">cancel</span>
            </div>
            <div class="modal-date-body">
                <div class="modal-date-input-title">Nova data de expiração</div>
                <div style="display: flex; justify-content: center;">
                    <datetime input-id="postpone-exp-date" ref="datepicker" :min-datetime="min_date" v-model="dateExpiration" auto :minute-step=10 type="datetime" class="form-control datetime-width"></datetime>
                    <span @click="open_datepicker()" class="material-icons-outlined date-icon">today</span>
                </div>
                
                <!-- <input type="datetime-local" v-model="dateExpiration" class="modal-date-input"> -->
                <div class="modal-date-butons">
                    <div class="modal-date-cancel-button" v-on:click="close()">Voltar</div>
                    <div class="modal-date-update-button" v-on:click="changeDeadline()">Atualizar</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import RequestService from "@/services/v3/request.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default {
    props: ["request"],
    data() {
        return {
            min_date: moment().toDate().toISOString(),
            dateExpiration: null,
            loading: false,
            svc: new RequestService(),
            modal: {
                title: `Prazo de digitação #${this.request.id}`,
                unpermitted_message: "Aceite o termo de consentimento para continuar.",
                permitted: true,
                submit : this.changeDeadline,
                subtitle: "Altere aqui a data e hora final da sua cotação",
                submit_text: 'Atualizar',
                style: {
                    width: "35%"
                }
            }
        };
    },
    methods: {
        open_datepicker() {
            document.getElementById('postpone-exp-date').click()
        },
        close(reload = false) {
            this.$emit("close", reload);
            if(reload) {
                this.$emit("reload");
            }
        },
        changeDeadline() {
            this.loading = true;

            this.svc.postpone(this.request.id, moment(this.dateExpiration).format("YYYY-MM-DD HH:mm"))
            .then(() => this.close(true))
            .catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
        this.dateExpiration = moment(this.request.expiration_date).toISOString();
    }
};
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    /deep/ { 

        #datetime-wrp {
            width: fit-content;
            .vdatetime {
                width: 16rem;
                display: inline-block;
            }
            .picker { 
                display: inline-flex;
                height: 37px;
                background: $blue1;
                width: 29px;
                align-items: center;
                justify-content: center;
                margin: 0;
                cursor: pointer;
                i {
                    color:white;
                }
            }
            .control-label {
                display: block;
            }
        }
        .bootstrap-datetimepicker-widget {
            td {
                .active,
                a.btn {
                    color: #f39c12;
                }
            }
        
            .day.active {
                background-color: #f39c12;
            }
        }
        .vdatetime-input {
            font-family:"Source Sans Pro", sans-serif;
            font-size: 14px;
            width: 100%;
            height: 100% !important;
            border: none;
            padding: 0;
            margin: 0;
        }
        .control-label {
            padding-left: 0;
            font-size: 1rem;
            font-family: $font-default;
        }
        .vdatetime-popup__header {
            background-color: $primary-color !important;
        }
        .vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
            background-color: $primary-color !important;
        }
        .vdatetime-popup__actions__button {
            color: $primary-color !important;
        }
        .vdatetime-time-picker__item--selected {
            color: $primary-color !important;
        }
    }

.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container {
  width: 50vw;
}
.modal-date-header{
    display: flex;
    background-color: var(--primary-color);
    padding: 1vh 1vw;
    border-radius: 12px 12px 0 0;
    font-weight: 500;
    font-size: 2.1em;
    color: #FFFFFF;
    justify-content: space-between;
}
.modal-date-close-icon{
    font-size: 1.5em;
    cursor: pointer;
}
.modal-date-body{
    padding: 2vh 1vw;
    background-color: white;
    border-radius: 0 0 12px 12px;
}
.modal-date-input-title{
    font-weight: 400;
    font-size: 1.5em;
    color: #A1A1A1;
    text-align: center;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.modal-date-input{
    width: 80%;
    padding: 1vh 1vw;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1.2em;
    color: #ABABAB;
    &:focus{
        outline: none;
    }
    &::-webkit-calendar-picker-indicator {
        filter: invert(24%) sepia(0%) saturate(1229%) hue-rotate(188deg) brightness(112%) contrast(81%);
        cursor: pointer;
    }
}
.modal-date-butons{
    display: flex;
    justify-content: center;
    margin-top: 7vh;
}
.modal-date-cancel-button{
    padding: 1.5vh 0;
    width: 15vw;
    text-align: center;
    border: 1px solid #FF7110;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1em;
    color: var(--primary-color);
    cursor: pointer;
}
.modal-date-update-button{
    padding: 1.5vh 0;
    width: 15vw;
    text-align: center;
    border: 1px solid #FF7110;
    background: var(--primary-color);
    border-radius: 4px;
    font-weight: 500;
    font-size: 1em;
    color: white;
    margin-left: 2vw;
    cursor: pointer;
}
.datetime-width {
  width: 60% ;
  border-radius: 8px ;
}
@media only screen and (max-width: 800px) {
    .modal-mask{ font-size: 2vw; }
    .modal-date-cancel-button{width: 45%;}
    .modal-date-update-button{width: 45%; margin-left: 0;}
    .modal-date-butons{justify-content: space-between;}
    .modal-date-input{width: 100%;}
    .datetime-width{width: 100%;}
}
@media only screen and (max-width: 600px) {
    .modal-mask{ font-size: 3vw; }
    .modal-content-container{width: 90vw;}
    .modal-date-body {padding: 2vh 3vw;}
}
@media only screen and (max-width: 400px) {
    .modal-mask{ font-size: 4vw; }
    .modal-content-container{width: 115vw;}
}
</style>
