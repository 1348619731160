<style lang="scss">
.btn_send {
  padding: 10px 15px;
  background: #f39c12;
  border: 1px solid #f39c12;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#inputCodigo {
	select {
		margin: 0;
	}
}
</style>
<template>
<transition name="modal">
	<div class="modal-mask">
		<div class="modal-wrapper">
			<div class="modal-container">
				<div class="modal-header">
					<slot name="header">
						<div class="row">
							<div class="col-sm-12" style="text-align: left;color: gray;">
							<h3 style="padding-right: 23px;text-align: left;color: gray;">Consolidação da Lista</h3>
							</div>
						</div>
					</slot>
				</div>

				<div class="modal-body">
					<div class="row">
						<div class="col-sm-12">
							<div class="form-group">
								<label for="inputCodigo" class="col-sm-12 control-label">Tipo de Consolidação:</label>
								<select class="form-control" v-model="consolidateTypeSelect">
									<option 
										v-for="option in consolidateTypes" 
										v-bind:key="'consolidate-' + option.value" 
										v-bind:value="option.value">
										{{option.name}}
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<slot name="footer">
						<button class="btn_close_modal" type="button" @click="close()">Cancelar</button>
						<button class="btn_send" type="button" @click="consolidateListMobile()" :disabled="loading">
							<i v-if="loading" class="fas fa-circle-notch fa-spin" style="margin-right: 5px;"></i>Consolidar
						</button>
					</slot>
				</div>
			</div>
		</div>
	</div>
</transition>
</template>

<script>
import QuotationService from "../../../services/QuotationService";
import ErrorHandlerService from "../../../services/ErrorHandlerService";

export default {
  props: ["listMobile"],
  data() {
    return {
		consolidateTypes: [
			{
                value: "S",
                name: "GERAR SOLICITAÇÃO"
			},
			{
                value: "C",
                name: "GERAR COTAÇÃO"
			}
		],
		consolidateTypeSelect: "S",
		loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
	},
    consolidateListMobile() {
      this.loading = true;
	    var quotationService = new QuotationService();
      quotationService
        .consolidateListMobile({
          listas: this.listMobile,
          tipo: this.consolidateTypeSelect
        })
        .then(response => response.data)
        .then(data => {
          this.close();
          this.$emit("finish");
        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
    }
  },
  mounted() {
	
  }
};
</script>
<style lang="scss" scoped>
@import '@/sass/commons/_variables';

input[type=time]::-webkit-clear-button {
  display: none;
}

input[type=time]::-webkit-inner-spin-button {
  display: none;
}

.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.control-label {
  color: $secondary-color;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
input {
  border-radius: 5px !important;
  margin-bottom: 5px;
}
.modal-container {
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.subtitle {
  color: #314b5f;
}
.btn_close_modal {
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #f39c12;
  color: gray;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
