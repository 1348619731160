import http from "../Http";
import Service from "../Service";

export default class CategoryService extends Service {

	all() {
		return http.get("listarcategoriascliente", {
			headers: this.getHeaders()
		});
	}
	
	update(data) {
		return http.post(`atualizarcategoria`, this.parse_params(data), {
			headers: this.getHeaders()
		});
	}

	create(data) {
		return http.post(`inserircategoria`, this.parse_params(data), {
			headers: this.getHeaders()
		});
	}

	delete(cat_id) {
		return http.post(`deletarcategoria`, {}, {
			headers: this.getHeaders(),
			params: { categoria_id: cat_id }
		});
	}

	parse_params(cat) {
		return {
			'categoria_id': cat.cat_id,
			'categoria_nome': cat.cat_descricao,
			'codigo_interno': cat.cat_codInterno
		}
	}
}
