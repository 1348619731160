<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
                <span class="new-product__header-title">
                    {{ client.head_office ? client.head_office.social_reazon : '' }}
                </span>
                <span @click="closeDialog" class="material-icons closebtn-icon">
                    cancel
                </span>
            </div>
            <div>
                <div class="page-section-select-container">
                    <div class="page-section-select-size" :class="isActiveContainer('invites')"
                    v-on:click="changeActiveTab('invites')">
                    <span class="material-icons-outlined desactive-icon" :class="isActiveIcon('invites')">
                        email
                    </span>
                    <div class="page-section-select-text">
                        Convites
                    </div>
                    </div>
                    <div class="page-section-select-size" :class="isActiveContainer('coments')"
                    v-on:click="changeActiveTab('coments')">
                    <span class="material-icons desactive-icon" :class="isActiveIcon('coments')">
                        person
                    </span>
                    <div class="page-section-select-text">
                        Comentários
                    </div>
                    </div>
                    <div class="page-section-select-size" :class="isActiveContainer('edit')"
                    v-on:click="changeActiveTab('edit')">
                    <span class="material-icons-round desactive-icon" :class="isActiveIcon('edit')">
                        storefront
                    </span>
                    <div class="page-section-select-text">
                        Dados de implantação
                    </div>
                    </div>
                    <div class="page-section-select-size" :class="isActiveContainer('cot')"
                    v-on:click="changeActiveTab('cot')">
                    <span class="material-icons-round desactive-icon" :class="isActiveIcon('cot')">
                        shopping_cart
                    </span>
                    <div class="page-section-select-text">
                        Dados de cotação
                    </div>
                    </div>
                </div>

                <CommentList v-if="pageTab == 'coments'" :client="client"
                 :commentable="{ ...client.attendance, type: 'ATTENDANCE' }" />
                <InvitationsModal v-if="pageTab == 'invites'" :client="client" />
                <EditTab v-if="pageTab == 'edit'" :setLoading="setLoading" :client="client" :close="closeDialog" :update="updatePage"/>
                <EditCotTab v-if="pageTab == 'cot'" :client="client" :setLoading="setLoading"/>
                <cc-loader-full v-if="isLoading"/>
            </div>
        </div>
    </div>
</template>

<script>
import CommentList from "@/components/lists/comments/comment-list.component";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import EditTab from "./EditTab.vue";
import EditCotTab from "./EditCotTab.vue";

export default {
    name: "MoraActionsModal",
    props: ['closeDialog', 'client', 'updatePage'],
    components: {
        CommentList,
        InvitationsModal,
        EditTab,
        EditCotTab
    },
    data() {
        return {
            pageTab: 'invites',
            isLoading: false
        }
    },
    methods: {
        setLoading(value){
          this.isLoading = value
        },
        isActiveTab(tab) {
            if (tab == this.pageTab) return true
            return false
        },
        isActiveContainer(tab) {
            if (this.isActiveTab(tab)) return 'page-section-select-size-active'
        },
        isActiveIcon(tab) {
            if (this.isActiveTab(tab)) return 'active-icon'
        },
        changeActiveTab(tab) {
            this.pageTab = tab
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  width: 95vw;
  min-height: 110vh;
  border-radius: 12px;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.page-section-select-container {
  border-bottom: 0.5px solid #E5E5E5;
  display: flex;
  align-items: flex-end;
  margin: 0em 2em;
}

.page-section-select-size {
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
  margin-right: 2vw;
}

.page-section-select-size:hover {
  background-color: #fdf0e9;
}

.page-section-select-size-active {
  cursor: default;
  border-radius: 8px 8px 8px 8px;
  margin-top: 20px;
  background-color: #fdf0e9;
}

.page-section-select-text {
  font-weight: 600;
  font-size: 1.1em;
  color: #202020;
  margin-left: 1vw;
}

.page-section-select-size:hover>.page-section-select-text {
  color: var(--primary-color);
}

.active-icon {
  color: var(--primary-color) !important;
}

.desactive-icon {
  color: var(--primary-color);
}

.active div {
  color: var(--primary-color);
}

.active {
  background-color: #fdf0e9;
}
</style>