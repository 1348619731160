<template>
	<!-- <cc-modal :modal="modal" @close="close">
		<div slot="body" class="container-fluid pt-0 sellers">
			<div class="row contents p-4 d-flex justify-content-center">
				<div class="col" id="sellers">
					<label for="" class="label-control">Fornecedores da compra/cotação</label>
					<div class="col mb-3">
						<div class="row">
							<div class="col p-0">
								<cc-search :placeholder="'Vendedor ou fornecedor'" class="mt-1" v-model="filters.name" @keyup="filter_sellers" />
							</div>
						</div>
					</div>
					<div class="list-group">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left px-3">Fornecedor</th>
									<th class="text-left px-0" v-show="!current_seller">Vendedor</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-show="seller.visible" v-for="seller in sellers" :key="seller.usu_id" @click="toggle_seller(seller)" class="pointer" :class="{ active: current_seller && current_seller.usu_id == seller.usu_id }">
									<td class="text-left px-3"><b>{{ seller.for_nome }}</b></td>
									<td class="text-left px-0" v-show="!current_seller">{{ seller.usu_nome }}</td>
									<td>
										<cc-button :icon="'fas fa-truck'" :content="'Produtos'" :classes="'fixed'" 	class="show-products"/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>




				<div id="seller-products" class="col" v-show="current_seller">
					<label for="" class="label-control"><b>#{{ current_seller ? current_seller.usu_nome : ''}}</b></label>
					<div class="row px-3 mb-3">
						<div class="col p-0">
							<cc-search :placeholder="'Nome do produto'" class="mt-1" v-model="query_params.produto" @keyup="filter_products" />
						</div>
						<div class="col-4 p-2 pl-3">
							<cc-checkbox :label="'Apenas com ultima oferta'" v-model="query_params.with_last_offer" :checked="query_params.with_last_offer == 1" />
						</div>
						<div class="col-3">
							<cc-button :content="'Copiar Preços'" :icon="'fa fa-copy'" @click="copy_prices"/>
						</div>
					</div>
					<div class="list-group p-2">
                        <div class="container delivery-form" v-if="current_seller">
                            <form href="#" @submit.prevent="save_delivery">
                                <div class="row">
                                    <div class="col">
                                        <label for="">Plano de Pagamento</label>
                                        <cc-select-v2 :tracker="'ttp_id'"
                                            :text="'ttp_nome'" :options="plans"
                                            @select="set_delivery_plan"
                                            v-model="current_seller.delivery.payment_plan"
                                        ></cc-select-v2>
                                    </div>
                                    <div class="col">
                                        <label for="">Data de Entrega</label>
                                        <datetime v-model="current_seller.delivery.date"
                                            @input="set_delivery_date"
                                            auto :minute-step=10
                                            type="date" class="form-control"></datetime>
                                    </div>
                                    <div class="col">
                                        <label for=""> </label>
                                        <cc-button :classes="'theme-2 fixed mt-1'"
                                            :disabled="!current_seller.delivery.payment_plan.ttp_id || !current_seller.delivery.date"
                                            :icon="current_seller.delivery.saved ? 'far fa-thumbs-up' : 'fa fa-save'"
                                            :content="current_seller.delivery.saved ? 'Salvo' : 'Salvar'" type="submit"
                                            :nolink="true" />
                                    </div>
                                </div>
                            </form>
                        </div>
						<table class="table" id="main-products">
							<thead>
								<tr>
									<th class="text-center">Cod.</th>
									<th>Produto</th>
									<th class="text-center px-4">Embalagem</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<template  v-for="product in products">

									<tr :key="'prod-' + product.pro_id" class="product shadow" v-show="!loading">
										<td class="text-center"><small>{{ product.ean }}</small></td>
										<td class="text-left"><b>{{ product.pro_descricao }} {{ product.marca }}</b></td>
										<td class="text-center">
											<small class="text-uppercase">{{ product.flag_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem }}</small>
										</td>
										<td>
                                            <cc-button v-show="product.flag_generico == 1" :icon="'fas fa-boxes'" :classes="'secondary show-all-brands'"
                                                :content="!product.show_all ? 'todos' : 'ultima oferta'" @click="toggle_brands(product)" />
                                        </td>
									</tr>
									<tr class="sub-products" v-show="!loading">
										<td colspan="4" class="p-3">
											<table class="table">
												<thead>
													<tr>
														<th v-if="product.flag_generico" class="px-3">Marca</th>
														<th class="text-right px-3">Preço Unitario</th>
														<th class="text-left px-3">Ultima oferta</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="subproduct in product.derivatives" :key="'prod-' + subproduct.pro_id" v-show="subproduct.visible">
														<td v-if="product.flag_generico" class="text-left">{{ subproduct.pro_observacao }}</td>
														<td class="text-center">
															<div class="d-flex prod-price-wrapper" :class="{ saved: subproduct.saved }">
																<cc-loader :style="{visibility: subproduct.loading ? 'visible' : 'hidden'}" :show_txt="false" class="prod-loader"/>
																<money v-model="subproduct.valor"
                                                                    v-bind="money" class="form-control"
                                                                    @keydown.native="handle_navigation($event, subproduct)"
                                                                    ref="price"  :data-visible="subproduct.visible"
                                                                    :disabled="(current_seller.owner_type && current_seller.owner_type !== 'CLIENT') || subproduct.is_discontinued ||
                                                                        (request.expired ||
                                                                        (((current_seller && !current_seller.forma) || !current_seller.data_entrega) && !current_seller.delivery.saved))" />
															</div>
														</td>
														<td class="text-left px-3 prod-curr">
                                                            {{ subproduct.last_offer | currency }}
                                                            <i v-if="!subproduct.is_discontinued"
                                                                class="fa fa-trash" @click="clear_offer(subproduct)"
                                                                title="Remover e desativar"
                                                                aria-hidden="true"></i>
                                                            <i v-if="subproduct.is_discontinued" class="fa fa-plus"
                                                                title="Reativar produto"
                                                                @click="continue_product(subproduct)" aria-hidden="true"></i>
                                                        </td>
														<td><cc-button :content="'salvar'" :classes="'fixed success'" @click="update_offer(subproduct)" /></td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</template>
                                <tr v-show="loading">
                                    <td colspan="4">
                                        <cc-loader />
                                    </td>
                                </tr>
							</tbody>
						</table>
					</div>
				</div>
				<cc-pagination
				:key="'pag'"
				classes="orange"
				:items_by_page_value="50"
				:total="total_items"
				:page="page"
				:page_limit="11"
				v-on:change="page = $event"
				></cc-pagination>
			</div>
		</div>
	</cc-modal> -->
	<div class="modal-mask">
		<div class="modal-content-header">
			Fornecedores
			<span class="material-icons modal-content-close" v-on:click="close()">cancel</span>
		</div>
		<div v-if="!current_seller" class="modal-content-body">
			<div class="modal-content-search-title">Fornecedor da Compra/Cotação</div>
			<div class="modal-content-search-inputs">
				<input type="text" v-model="filters.name" class="modal-content-search-input">
				<!-- <StandardButton class="modal-content-table-button" :action="filter_sellers" :iconName="'search'" :text="'Buscar'"/> -->
			</div>
			<cc-loader v-if="loading" style="max-height: fit-content;"></cc-loader>
			<div v-else class="modal-content-table-rows">
				<div class="modal-content-table-header">
					<div class="modal-content-table-suplier">Fornecedor</div>
					<div class="modal-content-table-seller">Vendedor</div>
					<div class="modal-content-table-status">Status</div>
					<div class="modal-content-table-action">Ações</div>
				</div>
				<div class="modal-content-table-row" v-show="seller.visible" v-for="(seller, idx) in sellers" :class="getSpecialBackground (idx)" :key="seller.id_vendedor">
					<div class="modal-content-table-suplier table-suplier-text">{{ seller.fornecedor_nome }}</div>
					<div class="modal-content-table-seller table-seller-text">{{ seller.vendedor_nome }}</div>
					<div class="modal-content-table-status"><cc-status :status="seller.status"></cc-status></div>
					<div class="modal-content-table-action" v-on:click="toggle_seller(seller)">
						<StandardButton class="modal-content-table-button" :action="() => {}"  :iconName="'local_shipping'" :text="'Produtos'"/>
					</div>
					<!-- <div class="modal-content-table-action">
						<span class="material-icons-outlined modal-content-table-icon" v-on:click="toggle_seller(seller)" title="Produtos">local_shipping</span>
					</div> -->
				</div>
			</div>
			<cc-pagination
				:key="'pag'"
				classes="orange"
				:items_by_page_value="50"
				:total="total_items"
				:page="page"
				:page_limit="11"
				v-on:change="page = $event"
			></cc-pagination>
		</div>
		<div v-else class="modal-content-body">
			<div class="modal-content-products-title">
				<div class="modal-content-products-title-text"># {{ current_seller ? current_seller.fornecedor_nome +' - '+ current_seller.vendedor_nome : ''}}</div>
				<StandardButton :action="() => {this.current_seller = undefined; this.load_sellers()}"  :iconName="'arrow_back'" :text="'Voltar'"/>
			</div>
			<div class="modal-content-products-filter2-container">
				<div class="modal-content-products-filter2-selectplan">
					Plano de Pagamento
					<cc-select-v2 :tracker="'ttp_id'"
						:text="'ttp_nome'" :options="plans"
						@select="set_delivery_plan"
						v-model="current_seller.delivery.payment_plan"
						style="border-radius: 8px;"
					></cc-select-v2>
				</div>
				<div class="modal-content-products-filter2-selectdate">
					Data de Entrega
					<div class="modal-content-products-filter2-selectdate-input">
						<datetime v-model="current_seller.delivery.date"
						@input="set_delivery_date"
						auto :minute-step=10
						type="date" class=""></datetime>
						<span class="material-icons-outlined" style="cursor: pointer;" v-on:click="open_datepicker()">event</span>
					</div>
				</div>
				<div class="modal-content-products-filter-checkbox">
					<span v-if="query_params.with_last_offer == 1" v-on:click="query_params.with_last_offer = 0" class="material-icons modal-content-products-filter-checkbox-active">check_box</span>
					<span v-else v-on:click="query_params.with_last_offer = 1" class="material-icons modal-content-products-filter-checkbox-active">check_box_outline_blank</span>
					Apenas com última oferta
				</div>
				<StandardButton class="modal-content-products-filter2-button" :action="save_delivery"  :iconName="current_seller.delivery.saved ? 'thumb_up' : 'save'" :text="current_seller.delivery.saved ? 'Salvo' : 'Salvar'"/>
			</div>
			<div v-if="current_seller.delivery.saved">
				<div class="modal-content-products-filter-title">Nome do produto</div>
				<div class="modal-content-products-filter-container">
					<input class="modal-content-products-filter-input" type="text" v-model="query_params.produto">
					<!-- <StandardButton class="modal-content-products-filter-button" :action="filter_products"  :iconName="'search'" :text="'Buscar'"/> -->
					<!-- <label :for="current_seller.id_vendedor" style="margin-bottom: 0;">
						<span class="material-icons-outlined modal-content-table-icon" title="Importar produtos">file_download</span>
					</label>
					<input :id="current_seller.id_vendedor" style="display: none;" type="file" v-on:change="event => loadExcel(event, current_seller)"> -->
					<StandardButton class="modal-content-products-filter-button" :action="copy_prices"  :iconName="'content_copy'" :text="'Copiar Preços'"/>
				</div>
			</div>
			<cc-loader v-if="loading" style="max-height: fit-content;"></cc-loader>
			<div v-else style="overflow-x: auto;">
				<div class="modal-content-products-table">
					<div class="modal-content-products-table-header">
						<div class="modal-content-products-table-cod">Código</div>
						<div class="modal-content-products-table-name">Descrição do produto</div>
						<div class="modal-content-products-table-wrap">Embalagem</div>
						<div class="modal-content-products-table-button"></div>
					</div>
					<div v-if="products.length < 1" class="modal-content-products-table-noproducts">Desculpe, não há produtos disponíveis.</div>
					<div v-else v-for="product in products" :key="'prod-' + product.pro_id" style="margin-bottom: 2vh">
						<div class="modal-content-products-table-row">
							<div class="modal-content-products-table-cod">{{ product.ean }}</div>
							<div class="modal-content-products-table-name">{{ product.pro_descricao }}</div>
							<div class="modal-content-products-table-wrap">{{ product.flag_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem }}</div>
							<div class="modal-content-products-table-button" v-on:click="toggle_brands(product)">
								<StandardButton class="modal-content-products-table-button-input"  :action="() => {}"   :text="!product.show_all ? 'Mostrar todos' : 'Ultima oferta'"/>
							</div>
						</div>
						<div class="modal-content-products-subtable-header">
							<div class="modal-content-products-subtable-brand">Marca</div>
							<div class="modal-content-products-subtable-price">Preço unitário</div>
							<div class="modal-content-products-subtable-last">Última oferta</div>
							<div class="modal-content-products-subtable-action">Ação</div>
						</div>
						<div class="modal-content-products-subtable-row" v-for="subproduct in product.derivatives" :key="'prod-' + subproduct.pro_id" v-show="subproduct.visible">
							<div class="modal-content-products-subtable-brand">{{ subproduct.pro_observacao }}</div>
							<div class="modal-content-products-subtable-price">
								<cc-loader-full v-if="subproduct.loading"/>
								<money v-model="subproduct.valor" :style="isProductSaved(subproduct)"
									v-bind="money" class="modal-content-products-subtable-price-input"
									@keydown.native="handle_navigation($event, subproduct)"
									ref="price"  :data-visible="subproduct.visible"
									:disabled="
									(current_seller.owner_type && current_seller.owner_type !== 'CLIENT')
									||
									subproduct.is_discontinued
									||
									(request.expired
										|| 
										((
											(current_seller && current_seller.entrega && !current_seller.entrega.forma)
											||
											( current_seller.entrega && !current_seller.entrega.data_entrega)
										) 
										&& 
										!current_seller.delivery.saved)
									)" />
							</div>
							<div class="modal-content-products-subtable-last">{{ subproduct.last_offer | currency }}</div>
							<div class="modal-content-products-subtable-action">
								<span v-if="!subproduct.is_discontinued" @click="clear_offer(subproduct)" class="material-icons-outlined modal-content-products-subtable-action-icon">delete</span>
								<span v-else @click="continue_product(subproduct)" class="material-icons-outlined modal-content-products-subtable-action-icon-add">add</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import SellersService from "@/services/v2/sellers.service";
import RequestsService from "@/services/v2/requests.service";
import ProductService from "@/services/v2/products.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import Pagination from "@/components/cliente/base-components/Pagination";
import Request from "@/models/request.model";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import { gsap } from "gsap";
import { Money } from "v-money";

// import readXlsxFile from 'read-excel-file'

export default {
	mixins: [ loaderMixin, elementNavigationmMixin, typingRemoteRequestControlMixin ],
	props: {
		request: {
			type: Object,
			required: true
		}
	},
	components: {
		"cc-pagination" : Pagination,
		StandardButton,
		Money
   	},
  	data() {
		return {
			inputs: 0,
			sellers: [],
			products: [],
            query_params: {
                produto: '',
                page: 1,
                with_last_offer: true
            },
			filters: {
				name: ''
			},
            plans: [

            ],
			current_seller: null,
			loading: true,
			total_items : 0,
            page: 1,
			is_loading_available_sellers: false,
			is_loading_request_sellers: false,
			service: new SellersService(),
			quotation_svc: new QuotationService(),
			req_svc: new RequestsService(),
            prod_svc: new ProductService(),
			request_model: null,
			modal: {
				title: `fornecedores #${this.request.numeroCotacao}`,
				subtitle: `Gerencie os produtos de sua compra/cotação`,
				icon_title: 'fas fa-users',
				cancel_text: 'Voltar',
				style: {
					width: "90%"
				}
			},
			money: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				precision: 2,
				masked: false
			}
		};
	},
  	methods: {
		isProductSaved(subproduct){
			if (subproduct.saved) return 'background-color: lightgreen'
		},
		getSpecialBackground (index) {
			if (index % 2 != 0) return 'page-table-line-special'
		},
		loadExcel(event, seller) {
			const payload = []
			this.loading = true
			readXlsxFile(event.target.files[0]).then(data => {
				for (let index = 1; index < data.length; index++) {
					const element = data[index];
					if(element[0] && element[2] != '#ERROR_#N/A') {
						payload.push({
							"preco": element[2],
							"product_ean": element[0],
							"seller_id": seller.id_vendedor,
							"cot_id": this.request.numeroCotacao,
							"for_id": seller.id_fornecedor
						})
					}
				}
				this.req_svc.importExcel(payload).then(() => {
					this.loading = false
				})
			})

		},
		open_datepicker() { document.getElementsByClassName('vdatetime-input')[0].click() },
		toggle_seller(seller) {
			let products_width = 70
			if(this.current_seller && this.current_seller.id_vendedor == seller.id_vendedor) {
				gsap.to('#seller-products', { duration: 1, width: 0 })
				gsap.to('#sellers', { duration: 1, 'max-width': "100%", 'flex': "0 0 100%"})
				this.current_seller = null
			} else {
                this.page = 1
				this.load_products(seller)
                if(!this.current_seller){
                    gsap.fromTo('#seller-products', { 'width': "0%", 'flex': `0 0 0%` }, { duration: 1, 'flex': `0 0 ${products_width-6}%`, ease: 'expo.out'})
                    gsap.fromTo('#sellers', { 'max-width': "100%", 'flex': '0 0 100%' }, { duration: 1, 'max-width': `"${100-products_width}%`, 'flex': `0 0 ${100-products_width}%`, ease: 'expo.out'})
                }
                this.current_seller = seller;
                if(!this.current_seller.data_entrega) {
                    this.current_seller.delivery.date = moment(this.request.dataExpiracao, 'YYYY-MM-DD').add("1", "days").format()
                }
				if (this.current_seller.entrega) this.current_seller.delivery.saved = true
			}
			this.$forceUpdate()
		},
		toggle_brands(product) {
            product.show_all = !product.show_all
            product.derivatives.filter(p => !p.last_offer).forEach(p => p.visible = !p.visible)
			this.$forceUpdate()
		},
		close() {
			this.$emit("close");
		},
        set_delivery_plan() {
            this.$set(this.current_seller.delivery, 'payment_plan', this.current_seller.delivery.payment_plan)
			this.$forceUpdate()
        },
        set_delivery_date() {
            this.$set(this.current_seller.delivery, 'date', moment(this.current_seller.delivery.date, 'YYYY-MM-DD').format())
			this.$forceUpdate()
        },
        save_delivery() {
            let data =  {
                data_entrega: moment(this.current_seller.delivery.date).format("YYYY-MM-DD"),
                faturamento_minimo: 0,
                forma_pagamento: this.current_seller.delivery.payment_plan.ttp_nome,
                numerocotacao: this.request.numeroCotacao,
                observacao: "",
                old: false,
                seller_id: this.current_seller.id_vendedor
            }
            this.current_seller.delivery.saved = false
            return this.quotation_svc.send_delivery(data).then(() => {
                this.current_seller.delivery.saved = true
                this.present_info("Entrega salva com sucesso")
                this.load_sellers()
            })
        },
		load_sellers() {
			this.loading = true
			return this.quotation_svc.get_request_providers(this.request.id, this.page)

			.then(response => response.data)
			.then(data => {
				this.sellers = data.concorrentes;
				this.total_items = data.concorrentes.length
				setTimeout(()=>{
					this.total_items = data.concorrentes.length
					this.$forceUpdate()
				},1000)
				this.sellers.forEach(s => s.visible = true)
                this.sellers.forEach(s => {
                    let del = this.plans.find(p => p.ttp_nome  == s.forma) || this.plans[0]
                    s.delivery = {
                        payment_plan: {
                            ttp_id: del ? del.ttp_id : '',
                            ttp_nome: del ? del.ttp_nome : '',
                        },
                        date: s.data_entrega ? moment(s.data_entrega, 'YYYY-MM-DD').format() : null
                    }
                })
                this.$forceUpdate();
				this.loading = false
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		load_products(seller) {

            this.loading = true

            this.cancelSearch(() => { this.loading = true });

            this.$forceUpdate();

            return this.req_svc.request_seller_products(this.request.numeroCotacao, seller.id_vendedor, this.page, this.query_params, this.cancelSource.token)
			.then(response => response.data)
			.then(data => {
                this.products = data.data
                // this.products.filter(p => !p.flag_generico).forEach(p => p.derivatives.push({ ...p }))
                this.products.forEach(p => this.process_visibility(p))
                this.loading = false
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		update_offer(product, seller_id = this.current_seller.id_vendedor) {
			this.$set(product, 'loading', true)
			return this.req_svc.update_request_product_offer(this.request.numeroCotacao, product.pro_id, { preco: product.valor, quantidadeDigitacao: 1, origem: "CLIENTE", vendedor: seller_id })
			.then(() => this.$set(product, 'loading', false))
            .then(() => this.$set(product, 'saved', true))
			.then(response => response.data)
			.catch(error => {
				this.$set(product, 'loading', false)
				ErrorHandlerService.handle(error, this.$store);
			});
		},
        enable_product(product) {

        },
        clear_offer(product) {
            this.$set(product, 'loading', true)
            return this.prod_svc.reset_product_last_offer(product.pro_id, this.current_seller.id_vendedor)
            .then(() => this.$set(product, 'loading', false))
            .then(() => this.$set(product, 'last_offer', 0))
            .then(() => this.$set(product, 'valor', 0))
            .then(() => this.$set(product, 'is_discontinued', 1))
            .then(() => this.update_offer(product))
			.catch(error => {
				this.$set(product, 'loading', false)
				ErrorHandlerService.handle(error, this.$store);
			});
		},
        continue_product(product) {
            this.$set(product, 'loading', true)
            return this.prod_svc.continue_product(product.pro_id, this.current_seller.id_vendedor)
            .then(() => this.$set(product, 'loading', false))
            .then(() => this.$set(product, 'saved', true))
            .then(() => this.$set(product, 'is_discontinued', 0))
			.catch(error => {
				this.$set(product, 'loading', false)
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		filter_sellers() {
			let filter = this.filters.name
			this.sellers.forEach(s => s.visible = false)
			this.sellers
			.filter(s => s.usu_nome.toLowerCase().includes(filter.toLowerCase()) || s.for_nome.toLowerCase().includes(filter.toLowerCase()))
			.forEach(s => s.visible = true)
			this.$forceUpdate()
		},
		filter_products() {
            this.load_products(this.current_seller)
		},
        process_visibility(p) {
            p.show_all = !this.query_params.with_last_offer
			// if(p.flag_generico == 1) {
				p.derivatives.forEach(p => p.visible = ((p.last_offer > 0 || p.is_discontinued) && this.query_params.with_last_offer) || !this.query_params.with_last_offer)
			// }
		},
		handle_navigation($event, product) {
			this.navigate($event, {
				callback: {
					function: this.update_offer,
					params: product
				},
				ref: 'price'
			})
		},
		copy_prices($evt, seller = this.current_seller) {
			this.confirm_action({
				message: "Confirma ação?",
				subtitle: "Todos os preços da ultima cotação serão atualizados nesta ",
				callback: () => {
					this.loading = true
                    if(!this.current_seller.data_entrega) {
                        this.save_delivery().then(() => {
                            return this.quotation_svc
                            .copy_prices(this.request.numeroCotacao, seller.id_vendedor)
                            .then(() => this.load_products(seller))
                        })
                    } else {
                        return this.quotation_svc.copy_prices(this.request.numeroCotacao, seller.id_vendedor)
                        .then(() => this.load_products(seller))
                    }
				}
			})
		}
	},
	watch: {
		"query_params.with_last_offer" : function() {
            this.load_products(this.current_seller)
		},
        "page" : function() {
			// this.load_products(this.current_seller)
			this.load_sellers()
		},
		"filters.name": function() {this.filter_sellers()},
		"query_params.produto": function(){
			if (!this.loading){
				this.loading = true
				setTimeout(() => this.load_products(this.current_seller), 1500)
			}
		}
	},
	mounted() {
		document.querySelector("body").style.overflow = 'hidden'
        this.quotation_svc.list_payment_types().then((resp) => resp.data).then((resp) => {
            this.plans = resp.data
            this.load_sellers()
            if(this.current_seller && !this.current_seller.forma) {
                this.current_seller.delivery.payment_plan.ttp_nome = this.plans[0].ttp_nome
            }
        })
	},
	destroyed() {
        document.querySelector("body").style.overflow = 'auto'
    }
};
</script>

<style lang="scss" scoped>
	@import "./manage-request-products-by-seller.modal";
	.modal-mask {
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	transition: opacity 0.3s ease;
	font-size: 1vw;
	flex-direction: column;
	padding-top: 10vh;
	}
	.modal-content-header {
	background-color: var(--primary-color);
	width: 80vw;
	border-radius: 10px 10px 0 0;
	padding: 2vh 2vw;
	font-weight: 500;
	font-size: 2em;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: space-between;
	}
	.modal-content-close{
		cursor: pointer;
		font-size: 1.5em;
	}
	.modal-content-body{
		background-color: white;
		width: 80vw;
		min-height: 100vh;
		border-radius: 0 0 10px 10px;
		padding: 2vh 2vw;
	}
	.modal-content-search-title{
		font-weight: 400;
		font-size: 1.4em;
		color: #808080;
	}
	.modal-content-search-inputs{
		display: flex;
		justify-content: space-between;
		margin-bottom: 2vh;
	}
	.modal-content-search-input{
		width: 100%;
		background: #FFFFFF;
		border: 0.5px solid #C1C1C1;
		border-radius: 8px;
		padding: 1vh 1vw;
		font-weight: 400;
		font-size: 1.4em;
		color: #808080;
		&:focus{
			outline: none;
		}
	}
	.modal-content-search-button{
		border: 0.5px solid #C1C1C1;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 10%;
		cursor: pointer;
		font-weight: 600;
		font-size: 1em;
		color: #202020;
	}
	.modal-content-search-button-icon{
		color: var(--primary-color);
		font-size: 1.6em;
	}
	.modal-content-table-header{
		display: flex;
		background: #FFFEFC;
		border: 0.5px solid #E5E5E5;
		padding: 1vh 1vw;
		margin-bottom: 3vh;
		font-weight: 600;
		font-size: 1.19em;
		color: #505050;
	}
	.modal-content-table-suplier{flex: 1;}
	.modal-content-table-seller{width: 30%;}
	.modal-content-table-status{width: 15%;}
	.modal-content-table-action{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1vw;
		width: 18%;
	}
	.modal-content-table-icon{
		color: var(--primary-color);
		height: 7.5vh;
		width: 5vw;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		cursor: pointer;
		border-radius: 5px;
		border: 1px solid #E5E5E5;
		font-size: 2.2vw;
		&:hover{
			background-color: var(--primary-color);
			color: white;
		}
	}
	.modal-content-table-rows{
		overflow-y: auto;
		height: 70vh;
		margin-top: 1vh;
	}
	.modal-content-table-row{
		display: flex;
		align-items: center;
		padding: 1vh 1.5vw;
	}
	.table-suplier-text{
		font-weight: 600;
		font-size: 1em;
		color: #605F5F;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.page-table-line-special {
		background: #F7F7F7;
	}
	.table-seller-text{
		font-weight: 400;
		font-size: 1em;
		color: #605F5F;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.modal-content-table-button{
		height: unset;
		padding: 0.75vh 1vw;
	}
	.modal-content-products-title{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}	
	.modal-content-products-title-text{
		font-weight: 600;
		font-size: 1.5em;
		color: var(--primary-color);
	}
	.modal-content-products-filter-title{
		font-weight: 400;
		font-size: 1.5em;
		color: #A1A1A1;
	}
	.modal-content-products-filter-container{display: flex; align-items: center;}
	.modal-content-products-filter-input{
		border: 1px solid #c2c2c3;
		border-radius: 8px;
		width: 65%;
		height: 95%;
		padding: 1vh 1vw;
		font-weight: 400;
		font-size: 1.5em;
		color: #A1A1A1;
		&:focus{outline: none;}
		margin-right: auto;
	}
	.modal-content-products-filter-button{margin-left: 2vw;}
	.modal-content-products-filter-checkbox{
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 300;
		font-size: 1.2em;
		color: #505050;
	}
	.modal-content-products-filter-checkbox-active{
		color: var(--primary-color);
		font-size: 1.5em;
		margin-right: 1vw;
		cursor: pointer;
	}
	.modal-content-products-filter2-container{
		display: flex;
		align-items: flex-end;
		margin-bottom: 3vh;
	}
	.modal-content-products-filter2-selectplan{
		width: 25%;
		font-weight: 400;
		font-size: 1.4em;
		color: #A1A1A1;
	}
	.modal-content-products-filter2-selectdate{
		width: 25%;
		font-weight: 400;
		font-size: 1.4em;
		color: #A1A1A1;
		margin-left: 2vw;
	}
	.modal-content-products-filter2-selectdate-input{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #c2c2c3;
		border-radius: 8px;
		padding: 0 2vw;
	}
	.modal-content-products-filter2-button{
		margin-left: auto;
	}
	.modal-content-products-table{
		border: 1px solid #c2c2c3;
		border-radius: 10px;
		margin-top: 3vh;
		max-height: 60vh;
		overflow-y: auto;
		min-width: 850px;
	}
	.modal-content-products-table-header{
		display: flex;
		padding: 1vh 0;
		font-weight: 600;
		font-size: 1.19em;
		color: #505050;
	}
	.modal-content-products-table-cod{
		margin-left: 4vw;
		width: 20%;
	}
	.modal-content-products-table-name{
		flex: 1;
	}
	.modal-content-products-table-wrap{
		width: 25%;
	}
	.modal-content-products-table-button{width: 15%;}
	.modal-content-products-table-row{
		display: flex;
		align-items: center;
		background: #FFEADB;
		padding: 1vh 0;
	}
	.modal-content-products-table-button-input{
		height: unset;
		min-width: unset;
		width: fit-content;
		padding: 0.5vh 1.5vw;
	}
	.modal-content-products-subtable-header{
		display: flex;
		font-weight: 600;
		font-size: 1em;
		color: #605F5F;
		padding: 2vh 0;
	}
	.modal-content-products-subtable-brand{
		margin-left: 7vw;
		flex: 1;
	}
	.modal-content-products-subtable-price{width: 15%;}
	.modal-content-products-subtable-last{width: 15%;}
	.modal-content-products-subtable-action{width: 10%;}
	.modal-content-products-subtable-row{
		display: flex;
		align-items: center;
		padding: 0.5vh 0;
		font-weight: 400;
		font-size: 1em;
		color: #605F5F;
	}
	.modal-content-products-subtable-price-input{
		width: 85%;
		&:focus{outline: none;}
		border: 1px solid #D1D1D1;
		border-radius: 8px;
		padding: 0.5vh 1vw;
		font-weight: 400;
		font-size: 1em;
		color: #605F5F;
	}
	.modal-content-products-subtable-action-icon{
		color: red;
		cursor: pointer;
		font-size: 1.75em;
	}
	.modal-content-products-subtable-action-icon-add{
		cursor: pointer;
		color: lightgreen;
		font-size: 1.75em;
	}
	.modal-content-products-table-noproducts{
		margin: 5vh 0;
		text-align: center;
		font-weight: 500;
		font-size: 2em;
		color: #909090;
	}
	@media only screen and (max-width: 1150px) {.modal-mask{font-size: 1.5vw;}}
	@media only screen and (max-width: 900px) {
		.modal-mask{padding-bottom: 10vh;}
		.modal-content-products-table{font-size: 18px;}
		.button-containear-comp{margin-right: 18px;}
		.modal-content-products-filter-container{flex-direction: column; gap: 2vh;}
		.modal-content-products-filter-input{width: 98%;}
		.modal-content-products-filter-button{margin-left: 0;}
		.modal-content-products-filter2-container{
			flex-direction: column;
			align-items: flex-start;
			text-align: center;
			gap: 2vh;
		}
		.modal-content-products-filter2-selectplan{width: 98%;}
		.modal-content-products-filter2-selectdate{
			margin-left: 0;
			width: 98%;
		}
		.modal-content-products-filter2-button{
			margin-left: 0;
			width: 98%;
		}
	}
	@media only screen and (max-width: 700px) {
		.modal-content-body{width: 105vw; overflow-y: auto;}
		.modal-content-header{width: 105vw;}
		.modal-mask{font-size: 2.5vw;}
		.modal-content-table-header{display: none;}
		.modal-content-table-row{
			flex-direction: column;
			margin-bottom: 3vh;
		}
		.modal-content-table-seller{
			width: 100%;
			text-align: center;
		}
		.modal-content-table-action{width: 50%;}
	}
	@media only screen and (max-width: 500px){
		.modal-mask{font-size: 4vw;}
		.modal-content-table-action{width: 75%;}
		.modal-content-table-row{font-size: 1.3em;}
		.modal-content-table-status{width: 40%;}
	}
	@media only screen and (max-width: 350px){
		.modal-content-table-action{width: 95%;}
	}
</style>
<style>
	.vdatetime-input{color: #a09c9c;}
</style>
