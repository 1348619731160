<style lang="scss" scoped>
@import "@/sass/commons/_variables";
@import '@/sass/commons/_tables';

.load-more {
	font-family: "Source Sans Pro", sans-serif;
	border: 1px solid;
	color: $primary-color;
	font-weight: 600;
	border-radius: 2px;
	padding: 1rem;
	margin: 1rem;
	&:hover {
		cursor: pointer;
		color: white;
		border: 1px solid $primary-color;
		background-color: $primary-color;
	}
}

</style>

<template>
	<div class="container-fluid shadow p-4">

        <cc-loader v-show="loading && page == 1" />
		
        <no-data-available v-if="orders.length == 0 && !loading" :text="'Sua lista de pedidos está vazia, acesso o menu lateral nova compra/requisição para novos pedidos'" />
        
        <div :class="{ hidden : orders.length == 0 || (loading && page == 1) }">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">Data</th>
                        <th>N Solicitação</th>
                        <th>Empresa</th>
                        <th>Fornecedor</th>
                        <th class="text-center">Produtos</th>
                        <th>Total</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in orders" v-bind:key="order.pedido_id">
                        <td class="text-center">{{ order.data_criacao | formatDate }}</td>
                        <td>#{{ order.pedido_id }}</td>
                        <td>{{ order.loja ? order.loja.loj_descricao : '' }}</td>
                        <td>{{ order.fornecedor ? order.fornecedor.for_nome : "" }}</td>
                        <td class="text-center">
                            <span class="list-products">{{ order.itens ? order.itens.length : 0 }}</span>
                        </td>
                        <td>{{ getTotal(order) | currency }}</td>
                        <td class="text-center">
							<cc-status :status="translateStatus(order.status)"></cc-status>
                        </td>
                        <td class="show-quotation">
							<cc-button :classes="'fixed secondary'" :link="{ name: 'cliente-monitores-pedido', params: { id: order.pedido_id } }" :content="'Mostrar'" />
                        </td>
                    </tr>
					<tr :class="{ hidden : !(loading && page > 1) }">
                        <td colspan="8">
                            <cc-loader />
                        </td>
                    </tr>
					<tr v-if="showPagination" class="text-center">
						<td colspan="8" style="height:6rem">
							<a v-on:click="paginate" class="load-more shadow">MOSTRAR MAIS</a>
						</td>
					</tr>
                </tbody>
            </table>
        </div>

    </div>
</template>
<script>
import OrderService from "@/services/OrderService";
import * as CONSTANTS from "@/constants/constants";
import ErrorHandlerService from "@/services/ErrorHandlerService";
	export default {
		data() {
			return { 
                page: 1,
                tab: 'DIRECT_PURCHASE',
				showPagination: false,
				orders: [],
				CONSTANTS: CONSTANTS,
				items_by_page_value: 5,
				service: new OrderService(),
				loading: true
			};
		},
		methods: {
			getTotal(order) {
				return order.itens.reduce((x, { preco_embalagem, quantidade }) => x + (preco_embalagem*quantidade), 0);
			},
			translateStatus(status) {
				switch(status) {
					case "AGUARDANDO_CONFIRMACAO":
						return "AGUARDANDO CONFIRMACAO";
					case "CONFIRMADO":
						return "CONFIRMADO";
					case "CANCELADO":
						return "CANCELADO";
					default:
						return "Não identificado!"
				}
			},	
			paginate() {
				this.page = this.page + 1;
				this.getDirectSaleOrders();
			},
			getDirectSaleOrders() {
				this.loading = true;
				this.service.getDirectSaleOrders({ page: this.page, page_size: this.items_by_page_value })
				.then(response => response.data)
				.then(data => {
					this.loading = false;
					this.orders = this.orders.concat(data.data);
					this.showPagination = data.next_page_url != null;
				}).catch(error => {
					this.loading = false;
					ErrorHandlerService.handle(error, this.$store);
				});;
			}
		},
		watch: {
			items_by_page_value: function() {
				this.orders = [];
				this.getDirectSaleOrders();
			}
		},
		mounted() {
			this.getDirectSaleOrders();
		}
	};
</script>