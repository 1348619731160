export const elementNavigationmMixin = {
    methods: {
        navigate(e, { callback, ref }) {
			let key = getKey(e)
			let inputs = this.$refs[ref].map(c => c.$el || c).filter(c => c.dataset.visible == 'true')
			let idx = inputs.indexOf(e.target)

			inputs.sort((a, b) => {
				let pos1 = parseInt(a.dataset.idx)
				let pos2 = parseInt(b.dataset.idx)
				if (pos1 > pos2) return 1
				if (pos2 > pos1) return -1
				return 0
			})

			let next_idx = idx+1 == inputs.length ? 0 : idx+1
			let prev_idx = idx == 0 ? inputs.length-1 : idx-1
			function getKey(e) {

				if (e.key) return e.key
				let keyFromCode = String.fromCharCode(e.keyCode)

				if (keyFromCode) return keyFromCode;
				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter"
				if (e.keyCode === 16) return "Shift"
				if (e.keyCode === 8) return "Tab"
				// etc
            }

            const default_callback = (x) =>  {
                inputs[x].focus()
                inputs[x].select()
            }

			if(key == "ArrowUp") {
				e.preventDefault()
				callback ? callback.function(callback.params).then(() => default_callback(prev_idx)) : default_callback(prev_idx)
			} else if(key == "ArrowDown" || key == "Tab" || key == "Enter") {
				e.preventDefault()
                callback ? callback.function(callback.params).then(() => default_callback(next_idx)) : default_callback(next_idx)
			} else if(key == "Enter") {
				e.preventDefault()
				callback ? callback.function(callback.params).then() : default_callback(idx)
			}
		},
		async navigatrix(e, { callback, x, y }) {
			let key = getKey(e)
			let inputsy = [], inputsx = []

			for(var ref in this.$refs) {
				if(ref.match(`y-${x}-`)) {
					inputsx.push(this.$refs[ref])
				}
				if(ref.match(new RegExp(`-${y}$`))) {
					inputsy.push(this.$refs[ref])
				}
			}
			inputsy = inputsy.flat()
			inputsx = inputsx.flat()
			let idy = y
			let idx = x

			inputsy.sort((a, b) => {
				let pos1 = parseInt(a.dataset.idy)
				let pos2 = parseInt(b.dataset.idy)
				if (pos1 > pos2) return 1
				if (pos2 > pos1) return -1
				return 0
			})

			inputsx.sort((a, b) => {
				let pos1 = parseInt(a.dataset.idx)
				let pos2 = parseInt(b.dataset.idx)
				if (pos1 > pos2) return 1
				if (pos2 > pos1) return -1
				return 0
			})

			let next_idx = idy+1 == inputsx.length ? 0 : idy+1
			let prev_idx = idy == 0 ? inputsx.length-1 : idy-1
			let next_idy = idx+1 == inputsy.length ? 0 : idx+1
			let prev_idy = idx == 0 ? inputsy.length-1 : idx-1
			function getKey(e) {

				if (e.key) return e.key
				let keyFromCode = String.fromCharCode(e.keyCode)

				if (keyFromCode) return keyFromCode;
				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter"
				if (e.keyCode === 16) return "Shift"
				if (e.keyCode === 8) return "Tab"
				// etc
			}
            let go = (i, inps) => {
                inps[i].focus()
                inps[i].select()
            }
			if(key == "ArrowRight") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(next_idx, inputsx)) : go(next_idx, inputsx)
			} else if(key == "ArrowLeft") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(prev_idx, inputsx)) : go(prev_idx, inputsx)
			} else if(key == "ArrowUp") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(prev_idy, inputsy)) : go(prev_idy, inputsy)
			} else if(key == "ArrowDown" || key == "Tab") {
				e.preventDefault()
				callback.function ? callback.function(callback.params).then(() => go(next_idy, inputsy)) : go(next_idy, inputsy)
			} else if(e.type == "blur") {
                callback.function ? callback.function(callback.params) : ''
            }
		}
    }
}
