<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .edit-amount {
        margin: auto;
        width: 200px;
        text-align: center;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .header-title {
        padding-right: 23px;
        text-align: left;
        color: gray;
        font-size: 22px;
    }

    .actions {
        i {
            color: $brand-danger !important;
            margin: 0 1rem;
            font-size: 1rem;
            cursor: pointer;
        }
    }
</style>
<template>
    <cc-modal :modal="modal" @close="close" id="products-modal">

        <div slot="body" class="row" style="min-height: 25rem">
            <div class="col-sm-10 only-print" style="text-align: left;color: gray;">
                <h4 style="padding-right: 23px;text-align: left;color: gray;">Produtos não encontrados</h4>
            </div>
            <div class="col-12">
                <div class="col-3 pb-3">
                    <cc-select-v2 :placeholder="'Selecionar categoria para todos'" :tracker="'cat_codInterno'" :text="'cat_descricao'" v-model="category" :options="categories" @select="set_category"></cc-select-v2>
                </div>
                <ValidationObserver ref="form" slim>
                <table class="table">
                    <thead>
                        <tr>
                            <th class="pl-4 text-left" style="width:10%">EAN</th>
                            <th class="pl-4" style="width:25%">Nome</th>
                            <th class="text-left">Embalagem</th>
                            <th class="text-center">Categoria</th>
                            <th class="text-center" style="width:6%">Qtd.</th>
                            <th class="text-center" style="width:6%">Estoque</th>
                            <th class="text-center">Observação</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="categories && processed">
                        <ProductComponent
                        v-for="item in products" v-bind:key="item.produto_id"
                        :wraps="wraps" :categories="categories" @remove="remove"
                        :item="item" />
                    </tbody>
                </table>
                </ValidationObserver>
            </div>
        </div>
    </cc-modal>
</template>

<script>

import RequestsService from "@/services/v2/requests.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import CategoryService from "@/services/v1/category.service";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import ProductComponent from './_product.component'

export default {
    mixins: [ loaderMixin ],
    props: { products: [ Array ] },
    components: { ValidationProvider, ValidationObserver, ProductComponent },
    data() {
        return {
            loading: false,
            svc: new RequestsService(),
            cat_svc: new CategoryService(),
            categories: [],
            category: null,
            processed: false,
            wraps: [
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Quilo', value: 'KG' },
                { name: 'Duzia', value: 'DZ' }
            ],
            modal: {
                title: `Importar novos produtos`,
                subtitle: 'Adicione os produtos de sua planilha que não estão na sua base',
                icon_title: 'fas fa-boxes',
                permitted: true,
                cancel_text: "Fechar",
                submit: this.save,
                style: {
                    width: "90%",
                    "min-height": "80%"
                }
            }
        };
    },
    methods: {
        set_category(category) {
            console.log(category)
            this.products.forEach(i => this.$set(i, 'category', category))
        },
        close() {
            this.$emit("close");
            this.$emit("reload");
        },
        save() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                this.present_loader("Importando novos produtos...")
                this.products.forEach(prod => prod.cat_id = prod.category ? prod.category.cat_id : null)
                this.products.forEach(prod => prod.pro_embalagem = prod.wrap ?  `${prod.wrap.value} ${prod.qtd_emb}` : null)
                this.svc.import_products(this.products)
                .then(response => {
                    this.dismiss_loader()
                    this.close();
                    this.$swal.fire( 'Sucesso!', 'Produtos importados com sucesso.', 'success');
                })
            })
        },
        remove(item) {
            this.confirm_action({
				message: "Remover produto?",
				callback: () => this.products = this.products.filter(i => i.pro_ean != item.pro_ean)
			})
        },
        load_categories() {
            this.present_loader("Carregando suas categorias...")
			return this.cat_svc.all()
			.then(response => response.data)
			.then(data => {
				this.categories = data.categorias;
				this.dismiss_loader()
			})
			.catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
        },
        process_categories() {
          	this.products.forEach(i => i.category = this.categories.find(cat => cat.cat_codInterno == i.cat_id))
        },
        process_wraps() {

              this.products.filter(i => i.pro_embalagem.split(" ").length > 1)
              .forEach(i => i.wrap = this.wraps.find(w => w.value == i.pro_embalagem.split(" ")[0]))

              this.products.filter(i => i.pro_embalagem.split(" ").length > 1 && !isNaN(i.pro_embalagem.split(" ")[1]))
              .forEach(i => i.qtd_emb = i.pro_embalagem.split(" ")[1])
              this.processed = true
        }
    },
    beforeMount() {
        this.load_categories().then(this.process_categories).then(this.process_wraps)
    }
};
</script>
