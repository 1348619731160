<style lang="scss" scoped>
  @import "./monitors.page";
</style>
<template>
  <div>
    <div class="monitors">
      <div class="body">
        <div class="container-fluid">
          <div class="row contents">
            <div class="col-lg-12 tabs">
              <div class="tab-content">
                <cc-requests-tab v-if="tab === 'cotacoes'"/>
                <cc-resupplies-tab v-if="tab === 'ressuprimento'"/>
                <cc-mobile-requests v-if="tab === 'mobile'" />
                <cc-orders v-if="tab === 'pedidos'" :request_id="request_id" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Header from "@/components/cliente/base-components/Header";
import * as CONSTANTS from "@/constants/constants";
import OrdersTab from "./tabs/orders/_orders.tab";
import RequestsTab from './tabs/_requests.tab'
import MobileRequestsTab from './tabs/_mobile.tab'
import ResuppliesTab from './tabs/_resupply.tab'
import VarejoFacilService from '@/services/VarejoFacilService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'

export default {
    mixins: [ loaderMixin ],
    props: {
        request_id: {
            type: Number
        },
        tab: {
            type: String,
            required: true,
            default: 'cotacoes'
        }
    },
    components: {
        ccHeader: Header,
        ccOrders: OrdersTab,
        ccRequestsTab: RequestsTab,
        ccResuppliesTab: ResuppliesTab,
        'cc-mobile-requests' : MobileRequestsTab,
    },
    data() {
        return {
            currentQuotation: null,
            CONSTANTS: CONSTANTS,
            vf_svc: new VarejoFacilService(),
            action_options: [
                { value: "", name: "Ações" },
                { value: "excluir", name: "Excluir" }
            ],
            breadcrumbs: [
                { name: "Painel de Operações", route: 'monitors-page' }
            ],
            list: [],
            searchInput: "",
            showPagination: false,
            page: 1,
            items_by_page_value: 10,
        }
    },
    mounted() {
        this.$store.dispatch('set_statuses');
    }
};
</script>
