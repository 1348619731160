<template>
    <div >
      <div class="upload-files-container">
        <label for="upload" class="upload-label">
        <i class="fas fa-cloud-upload-alt"></i> Enviar Arquivos
        </label>
        <input id="upload" type="file" multiple @change="uploadFiles($event)">
      </div>

      <cc-loader v-show="loading" class="default" v-if="loading" />
      <no-data-available style="margin-top: 3em;" v-if="comments.length == 0 && !loading" :text="'Nenhum comentário enviado'" />
      <div v-if="comments.length && !loading" class="main-comments-container">
        <div style="margin: 2em;" v-for="comment in visible_comments" :key="comment.id" >
          <div class="comment-modal__edit-user-container">
            <div class="comment-modal__edit-user-wrapper">
              <span v-if="!comment.isChecked" class="material-icons-round person-icon">
                person
              </span>
              <span style="color: var(--primary-color);" v-if="comment.isChecked" class="material-icons-round person-icon">
                person
              </span>
              {{ comment.sender.name }}
            </div>
            <div class="comment-modal__edit-user-wrapper">
              <span @click="() => edit(comment)" v-if="can('RESALE_ID', 'READ') && !comment.isChecked" class="material-icons-round edit-icon">
                edit
              </span>
              <span style="color: var(--primary-color);" @click="() => edit(comment)" v-if="can('RESALE_ID', 'READ') && comment.isChecked" class="material-icons-round edit-icon">
                edit
              </span>
              <span @click="() => remove(comment)" v-if="can('RESALE_ID', 'READ')" class="material-icons-round remove-icon">
                delete
              </span>
            </div>
          </div>
          <div class="comment-modal__comment-wrapper" v-if="!comment.is_editing">
            <span class="comment-modal__comment-title">{{ comment.message }}</span>
            <br>
            <span class="comment-modal__comment-date">{{ comment.created_at | formatDate }}</span>
          </div>
          <div class="comment-body" v-else>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form action="" @submit.prevent="handleSubmit(save)">
                <div>
                  <div>
                    <ValidationProvider rules="required" v-slot="v">
                      <textarea class="form-control" v-model="comment.message" :disabled="!can('RESALE_ID', 'READ')" />
                      <span class="error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div style="display: flex; justify-content: flex-end;">
                  <StandardButton :action="() => { handleSubmit(save) }" type="submit" id="submit-comment" :text="'Salvar'" v-if="can('RESALE_ID', 'READ')"/>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="form-container">
        <div class="form-wrapper" v-if="!is_editing()">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form action="" @submit.prevent="handleSubmit(save)">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <ValidationProvider rules="required" v-slot="v">
                    <textarea class="form-control" v-model="current_comment.message" :disabled="!can('RESALE_ID', 'READ')"/>
                    <span class="error">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-buttons">
                <StandardButton style="margin-left: 1em;" :action="() => { handleSubmit(save) }" type="submit" id="submit-comment" :text="'Enviar'" v-if="can('RESALE_ID', 'READ')"/>
                <StandardButton ref="scrollToMe" :action="close" :text="'Fechar'"/>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as CONSTANTS from '@/constants/constants';
import CommentService from '@/services/v3/comments/comment.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import { perm_mixin } from '@/mixins/permission.mixin'
import StandardButton from '@/components/ui/buttons/StandardButton.vue';

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: {
          ValidationProvider,
          ValidationObserver,
      StandardButton,
    },
    props: [ "commentable", "client", "closeDialog" ],
    data() {
        return {
            svc: new CommentService(),
            comments: [],
            loading: true,
            current_comment: {
                message: ""
            },
            files: [],
            clientId: undefined,
        }
    },
    methods: {
      uploadFiles(e) {
        this.$swal.fire(
          'Fazendo upload dos arquivos...'
        );

        this.files = e.target.files;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          const fileNameWithoutSpaces = this.files[i].name.replace(/\s/g, ''); // Remove espaços do nome do arquivo
          formData.append('archives[]', this.files[i], fileNameWithoutSpaces);
        }

        axios.post(`${CONSTANTS.API_V3_URL}/admin/clients/archives/${this.client.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`, 
          }
        })
        .then(response => {
          this.$swal.fire(
            'Sucesso!',
            'Feito upload dos arquivos.',
            'success'
          );
        })
        .catch(error => {
          this.$swal.fire(
            'Erro!',
            'Já existem arquivos com esse nome.',
            'error'
          );
        });
      },
        close() {
          this.$emit('close')
        },
        edit(comment) {
            comment.isChecked = true
            this.current_comment = comment
            this.comments.filter(c => c.id != comment.id).forEach(c => c.is_editing = false)
            this.current_comment.is_editing = !comment.is_editing
            if(!comment.is_editing) {
                this.current_comment = {}
                comment.isChecked = false
            }
            this.$forceUpdate()
        },
        remove(comment) {
            this.confirm_action({
                message: "Confirma remoção do comentário?",
                callback: () => {
                    this.loading = true
                    this.svc.remove(comment.id).then(() => {
                        this.loading = false
                        this.load_comments()
                        const notification = {
                            type: 'success',
                            message: 'Comentário removido!'
                        }
                        this.$store.dispatch('notification/add', notification)
                    })
                }
            })
        },
        save() {
            this.loading = true
            let data = {
                ...this.current_comment,
                message: this.current_comment.message,
                commentable_id: this.commentable.id,
                commentable_type: this.commentable.type,
            }
            this.svc.save(data).then(() => {
                this.loading = false
                this.load_comments(!this.current_comment.id)
                this.current_comment = {}
                const notification = {
                    type: 'success',
                    message: 'Comentário enviado!'
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        load_comments(scroll = true) {
            this.svc.load({ id: this.commentable.id, type: 'ATTENDANCE' })
            .then(resp => resp.data).then(data => {
                this.comments = data
                this.loading = false
                if(scroll) {
                    Vue.nextTick(() => {
                        this.scrollToElement()
                    })
                }
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store);
                const notification = {
                    type: 'error',
                    message: 'Ocorreu um erro ao alterar vencedor. Tente novamente.'
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        scrollToElement() {
            const el = this.$refs.scrollToMe;

            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.$el.scrollIntoView({behavior: 'smooth'});
            }
        },
        is_editing() {
            return this.comments.some(c => c.is_editing)
        }
    },
    computed: {
        visible_comments() {
            let editing = this.comments.find(c => c.is_editing)
            return this.comments.filter(c => !editing || c.id == editing.id)
        }
    },
    mounted() {
        this.load_comments();
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';
    /deep/ .no-data-available {
        font-size: 1.3rem;
        i {
            font-size: 2rem;
        }
    }
    .component-wrapper {
        position: absolute;
        bottom: 10vh;
        max-height: 70vh;
        width: 40vw;
        overflow: auto;
        padding: 3rem;
        background: white;
        border-radius: 5px;
        .list-header {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            span {
                text-shadow: -1px 0px #5e72e4;
                margin: 0;
            }
            i {
                margin-right: 5px;
                color: $ternary-color;
            }
        }
        .comment {
            padding: 10px;
            .comment-header {
                display: flex;
                justify-content: space-between;
                padding-left: 5px;
                font-weight: bolder;
                font-size: 0.8rem;
                i {
                    color: $secondary-color;
                    font-size: 0.9rem;
                }
                span.remove-icon {
                    color: red;
                }
            }
            .comment-body {
                padding: 10px;
                text-decoration: wavy;
                border: 1px solid #eae2e2;
                border-radius: 5px;
                color: $primary-color;
                span:last-child {
                    display: block;
                    text-align: end;
                    padding-right: 12px;
                    color: $gray3;
                    font-size: 0.8rem;
                }
            }
        }
    }

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container {
  background-color: white;
  min-width: 90vw;
  max-width: 90vw;
  min-height: 110vh;
  border-radius: 12px;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}
.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.comment-modal__edit-user-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5em;
  margin-bottom: 1em;
}
.comment-modal__edit-user-wrapper {
  display: flex;
  align-items: center;
}
.person-icon {
  color: #000000;
  margin-right: 10px;
}
.edit-icon {
  margin-right: 10px;
  color: #505050;
  cursor: pointer;
}
.remove-icon {
  color: #B3251C;
  cursor: pointer;
}
.main-comments-container {
  max-height: 50vh;
  overflow-y: auto;
}
.comment-modal__comment-wrapper {
  border: 1px solid #F1F1F1;
  padding: 1em 2em;
  border-radius: 8px;
}
.comment-modal__comment-title {
  color: #909090;
  font-weight: 300;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: 0.15px;
}
.comment-modal__comment-date {
  color: #909090;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.15px;
  font-style: italic;
  display: flex;
  justify-content: flex-end;
}
.form-control {
  padding: 1em;
  color: #909090 !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.15px;
}
.form-container {
  padding: 2em;
}
.form-wrapper {}
.form-buttons {
  display: flex;
  flex-direction: row-reverse;
}
/* .upload-files-container {
  display: flex;
  margin: 2em;
  justify-content: space-between;
  flex-direction: row-reverse;
} */

.upload-files-container {
  display: flex;
  margin: 2em;
  flex-direction: row-reverse;
}

.upload-label {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 20px 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}
.upload-label:hover {
  background-color: var(--primary-color);
  opacity: 0.8;
}

input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

/deep/
.no-data-available {
  font-size: 60px;
}

@media only screen and (max-width: 800px) {
  .modal-content-container{width: 115vw; min-width: none; max-width: none;}
  .button-containear{width: 45%;}
  .form-buttons {justify-content: space-between;}
}

</style>
