export default class Request {

    cot_dataExpiracao;

    CTYPE;

    static convert(list) {
        return list.map(r => Object.assign(new Request(), r));
    }

    get expired() {
        return this.CTYPE == "DEFAULT" && new Date(this.cot_dataExpiracao).getTime() < new Date().getTime()
    }

}