<template>
    <div class="tab-pane fade show active in" id="resupply" role="tabpanel" aria-labelledby="resupply-tab">
        <form @submit.prevent="load_resupplies">
            <div class="container-fluid">
                <div class="row">
                    <cc-mobile-note />
                    <div class="col-8 d-flex align-items-center buttons">
                        <cc-button :disabled="resupplies.length == 0" :icon="'fa fa-share-square'" :content="'Enviar Cotação'" @click="sendResupply" />
                        <cc-button :disabled="resupplies.length == 0" :icon="'fa fa-trash'" :content="'Apagar Solicitações'" @click="deleteQuote" />
                        <cc-button :icon="'fa fa-file-excel'" :content="'Importar'" @click="showImportExcel = true" />
                        <cc-button :disabled="resupplies.length == 0" :icon="'fas fa-compress'" :content="'Agrupar Solicitações'" @click="mergeResupply" />

                    </div>
                </div>
            </div>
        </form>
        <div class="container-fluid shadow p-4 mt-4">

            <no-data-available v-if="resupplies.length == 0 && !loading" :text="'Sua lista de ressuprimento está vazia'" />

            <div v-if="resupplies.length > 0" class="table-responsive m-0">
                <cc-loader v-show="loading"/>
                <table class="table" :class="{ hidden : loading }">
                    <thead>
                    <tr>
                        <th>
                        <input
                            class="custom-checkbox"
                            :id="'checkbox-all'"
                            type="checkbox"
                            @change="checkAllResupply"
                        />
                        <label class="check-title checkBoxTableHeader" :for="'checkbox-all'"></label>
                        </th>
                        <th></th>
                        <th>Data</th>
                        <th>N Solicitação</th>
                        <th class="w-25">Empresa</th>
                        <th>Produtos</th>
                        <th>Funções</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in resupplies" :key="item.numeroCotacao + Math.random()">
                        <td>
                            <input
                                v-model="resupplySelect"
                                class="custom-checkbox"
                                :id="'checkbox-'+item.numeroCotacao"
                                type="checkbox"
                                :value="item.numeroCotacao"
                            />
                            <label :for="'checkbox-'+item.numeroCotacao"></label>
                        </td>
                        <td >
                            <a>
                                <span class="fas fa-exchange-alt button-alter" @click="showChangeStore(item)" title="Alterar Empresa"></span>
                            </a>
                        </td>
                        <td>{{ item.dataCotacao}}</td>
                        <td>{{ item.numeroCotacao}}</td>
                        <td class="span-loja text-left" >{{ `${item.nomeLoja} ${item.nomeSolicitacao ? `(${item.nomeSolicitacao})` : '' }` }}</td>
                        <td>{{item.total_produtos}}</td>
                        <td class="show-quotation">
                            <a>
                                <span @click="resupplyClick = item.numeroCotacao; showProductResupply = true">Mostrar</span>
                            </a>
                        </td>
                        <td>
                            <cc-status
                                :status="item.status === 8 ? 'ENVIADA P/ CENTRAL' : ''"
                            ></cc-status>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ModalProductsResupply id="modal" :quotation="resupplyClick" v-if="showProductResupply" @close="closeProductResupply()" @loadRessuply="load_resupplies()" />
        <ModalImportExcel id="modal" @finish="importSuccess" v-if="showImportExcel" @close="closeImportExcel()" />
        <ModalImportNewProducts id="modal-new-products" @reload="load_resupplies()" @close="new_products = []" v-if="new_products && new_products.length > 0" :products="new_products" />
        <ModalSendResupply id="modal" :quotations="resupplySelect" @finish="publishSuccess();" v-if="showSendResuply" @close="closeSendResuply()" />
        <ModalChangeStore id="modal" @finish="changeStoreSuccess" :ressuply="resupplySelected" v-if="showModalChangeStore" @close="closeModalChangeStore()" />
    </div>
</template>
<script>
import QuotationService from '@/services/QuotationService';
import ModalProductsResupply from "@/modals/resupply-products/resupply-products.modal";
import ModalImportExcel from "@/modals/import-resupply/import-resupply.modal";
import ModalImportNewProducts from "./resupply/import-products.modal";
import ModalSendResupply from "@/modals/send-resupply/send-resupply.modal";
import ModalChangeStore from "@/modals/change-store/change-store.modal";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import NoDataAvailable from "@/components/utils/no-data.utils"
import StorageService from '@/services/local-storage.service';
import RequestsService from "@/services/v2/requests.service";

export default {
    components: {
        ModalProductsResupply,
        ModalImportExcel,
        ModalSendResupply,
        ModalImportNewProducts,
        ModalChangeStore,
        'no-data-available': NoDataAvailable
    },
    data() {
        return {
            loading: false,
            resupplies: [],
            listResupplies: [],
            new_products: [],
            service: new QuotationService(),
            req_svc: new RequestsService(),
            resupplySelect: [],
            showProductResupply: false,
            showSendResuply: false,
            showImportExcel: false,
            showModalChangeStore: false,
            resupplySelected: null
        }
    },
    methods: {
        sendResupply: function() {
            if (this.resupplySelect.length > 0) {
                this.showSendResuply = true;
            } else {
                this.$swal.fire("Erro!", "Selecione uma cotação.", "error");
            }
        },
        importSuccess(new_products) {
            if(new_products) {
                this.new_products = new_products.map(p => {
                    let obj = p[Object.keys(p)[0]];
                    return {
                        'cot_id': Object.keys(p)[0],
                        'pro_ean': obj[0],
                        'pro_descricao': obj[1],
                        'pro_embalagem': `${obj[2]} ${obj[3]}`,
                        'estoque': obj[4],
                        'quantidade': obj[7],
                        'cat_id': obj.length > 8 ? obj[8] : null,
                        'pro_observacao': obj.length > 10 ? obj[10] : null
                    }
                })
            }
            this.$swal.fire(
                "Importado!",
                "Sua cotação foi importada com sucesso.",
                "success"
            );
            this.load_resupplies()
        },

        changeStoreSuccess() {

            this.$swal.fire(
                "Alterado!",
                "A troca da empresa foi efetuada com sucesso.",
                "success"
            );
            this.load_resupplies()
        },
        publishSuccess() {
            this.$swal
                .fire("Publicado!", "Sua cotação foi publicada com sucesso.", "success")
                .then(() => this.load_resupplies())
                .then(() => this.resupplySelect = [])
                .then(() => this.$router.push({ name: 'monitors-page', params: { tab: 'cotacoes' }}));
        },
        closeProductResupply: function() {
            this.showProductResupply = false;
        },
        load_resupplies(dataIni, cliente, status, numerocotacao) {
            this.service
                .listResupply()
                .then(response => response.data)
                .then(data => {
                this.resupplies = data.cotacoes.data;
                this.loading = false;
                })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        checkAllResupply(e) {
            this.resupplySelect = [];
            if (e.target.checked) {
                this.resupplies.forEach(element => {
                    this.resupplySelect.push(element.numeroCotacao);
                });
            }
        },
        closeImportExcel: function() {
            this.showImportExcel = false;
        },
        closeSendResuply: function() {
            this.showSendResuply = false;
        },
        closeModalChangeStore: function() {
            this.showModalChangeStore = false;
        },
        deleteQuote: function() {
            if (this.resupplySelect.length > 0) {
                this.$swal
                .fire({
                    title: "Tem certeza que deseja deletar a solicitação?",
                    text: "Sua solicitação será apagada.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo deletar!"
                })
                .then(result => {
                    if (result.value) {
                        this.service
                        .deletequotationresuply(this.resupplySelect)
                        .then(response => response.data)
                        .then(data => {
                            this.load_resupplies();

                            this.$swal.fire(
                                "Deletado!",
                                "Sua soliticação foi apagada com sucesso.",
                                "success"
                            );
                        })
                        .catch(error => {
                            this.$swal.fire(
                                "Erro!",
                                "Ocorreu um erro ao realizar a ação.",
                                "error"
                            );
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                })
            } else {
                this.$swal.fire("Ops!", "Selecione uma cotação!", "warning");
            }
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                {
                    name: "Painel de Operações",
                    route: 'monitors-page'
                },
                {
                    name: "Ressuprimento",
                    route: 'monitors-page'
                }
            ]);
        },
        mergeResupply(){
            if(this.verifyStoreResupply()){
                this.$swal
                .fire({
                    title: "Tem certeza que deseja agrupar as solicitações?",
                    text: "Suas solicitações serão agrupadas.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, desejo agrupar!"
                })
                .then(result => {
                    if (result.value) {
                        this.service
                        .mergequotationresuply(this.resupplySelect)
                        .then(response => response.data)
                        .then(data => {

                            this.resupplySelect = []

                            this.load_resupplies();

                            this.$swal.fire(
                                "Agrupado!",
                                "Suas solicitações foram agrupadas com sucesso.",
                                "success"
                            );
                        })
                        .catch(error => {
                            this.$swal.fire(
                                "Erro!",
                                "Ocorreu um erro ao realizar a ação.",
                                "error"
                            );
                            ErrorHandlerService.handle(error, this.$store);
                        });
                    }
                })

            }else{
                this.$swal.fire("Ops!", "Selecione solicitações da mesma loja!", "warning");
            }
        },
        verifyStoreResupply(){
            let list = [];
            this.resupplySelect.forEach(resupply=>{
                this.resupplies
                    .filter(r => resupply == r.numeroCotacao)
                    .map(x => list.push(x));
            });
            let result = false;
            let uniqueStore = [...new Set(list.map(item => item.loj_id))];
            if(this.resupplySelect.length > 0 && uniqueStore.length == 1){
                result = true;
            }
            return result;
        },
        showChangeStore(ressuply){
            this.resupplySelected = ressuply;
            this.showModalChangeStore = true;
        }

    },
    created() {
        this.load_resupplies()
        this.update_breadcrumbs()
    }
}
</script>
<style lang="scss" scoped>
    @import "../monitors.page";

    /deep/ .buttons {
        a {
            margin-left: 10px;
        }
    }
    .checkBoxTableHeader{
        margin-left: 0px !important;
    }
    .button-alter{
        cursor: pointer;
        font-size: 20px;
    }
</style>
