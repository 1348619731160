import http from "./Http";
import Service from "./Service";
import moment from "moment";

/**
 * @type QuotationService
 */
export default class QuotationService extends Service {

    getOpenQuotations() {
        return http.get("varejofacil/list", {
            headers: this.getHeaders(),
            timeout: 600000
        });
    }

    importQuotation(id, store_id) {
        return http.get(`varejofacil/import/${id}`, {
            headers: this.getHeaders(),
            timeout: 600000,
            params: {
                store_id: store_id
            }
        });
    }

    import_products() {
        return http.get(`varejofacil/products/import`, {
            headers: this.getHeaders(),
            timeout: 600000
        });
    }

    setup_alert(settings) {
        if(settings) {
            settings.forEach(s => {
                let today = moment()
                if(s.key == "LAST_VAREJO_FACIL_SYNC_DATE" && today.diff(moment(s.value), 'days') >= 7) {
                    let last_date = localStorage.getItem('LAST_LATE_SYNC_WARNING_TIMESTAMP')
                    if(last_date) {
                        let date = moment(last_date)
                        let diff = today.diff(date, 'days')
                        if(diff >= 1) {
                            localStorage.setItem('EXECUTE_VF_LAST_LATE_SYNC_WARNING', true)
                            localStorage.setItem('LAST_LATE_SYNC_WARNING_TIMESTAMP', new Date())
                        }
                    } else {
                        localStorage.setItem('EXECUTE_VF_LAST_LATE_SYNC_WARNING', true)
                        localStorage.setItem('LAST_LATE_SYNC_WARNING_TIMESTAMP', new Date())
                    }
                }
            })
        }
    }

    should_execute_alert() {
        return JSON.parse(localStorage.getItem('EXECUTE_VF_LAST_LATE_SYNC_WARNING')) || false
    }

    execute_alert() {
        if(this.should_execute_alert()) {
            localStorage.setItem('EXECUTE_VF_LAST_LATE_SYNC_WARNING', false)
            localStorage.setItem('LAST_LATE_SYNC_WARNING_TIMESTAMP', new Date())
            return true
        }
        return false
    }

}
