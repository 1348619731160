import http from "../http";
import Service from "../../Service";

/**
 * @type CommentService
 */
export default class CommentService extends Service {

    load(params) {
        return http.get(`admin/comments`, {
			headers: this.getHeaders(),
            params
		});
    }

    create(comment) {
        return http.post(`admin/comments`, comment, {
			headers: this.getHeaders()
		});
    }

    update(comment) {
        return http.put(`admin/comments/${comment.id}`, comment, {
			headers: this.getHeaders()
		});
    }

    remove(id) {
		return http.delete(`admin/comments/${id}`, {
			headers: this.getHeaders()
		});
	}

    save(comment) {
        return comment.id ? this.update(comment) : this.create(comment);
    }
}
