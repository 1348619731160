<template>
    <div class="modal-mask">
      <div class="modal-content-container">
        <div class="request-providers__main-container">
          <div class="request-providers__header-container">
            <span class="request-providers__header-title">
              Fornecedores da cotação #{{ request_id }}
            </span>
            <span @click="closeDialog" class="material-icons request-providers__closebtn-icon">
              cancel
            </span>
          </div>
          <div class="request-providers__providers-info__container">
            <div class="request-providers__total-providers__wrapper">
              <span class="request-providers__total-providers__title">Total de Fornecedores - ({{providers.length}})</span>
            </div>
            <div class="request-providers__engaged-providers__wrapper">
              <span class="request-providers__engaged-providers__title">Fornecedores Engajados - ({{get_providers_participant()}})</span>
            </div>
            <div class="request-providers__total-entries">Quantidade de registros: {{filterProvider(providers).length}}</div>
            <StandardInput style="margin-left: 2vw;" :placeholder="'Selecione o status'" 
                     :action="setStatusValue" 
                     :width="'25vw'" 
                     :list="arraySelectStatus" 
                     :type="'select'"/>
            <div class="request-providers-back-button" v-if="VIEW != 'LIST'" v-on:click="VIEW = 'LIST'">Voltar</div>
          </div>
          <cc-loader-full v-if="loading" />
          <div v-else-if="VIEW == 'LIST'" class="request-providers__table-container">
            <table class="request-providers__table-wrapper">
              <thead class="request-providers__table-thead">
                <tr class="request-providers__table-thead-tr">
                  <th class="request-providers__table-th request-providers__table-th-provider">Fornecedor</th>
                  <th class="request-providers__table-th">
                    Vendedor / Email
                  </th>
                  <th class="request-providers__table-th">Telefone</th>
                  <th class="request-providers__table-th request-providers__table-th-cond">Cond. Pgto. / Entrega</th>
                  <th class="request-providers__table-th request-order-container" @click="toggleQtd">Quantidade
                    <span v-if="qtdOrder == 'up'" class="material-icons-outlined">arrow_upward</span>
                    <span v-else-if="qtdOrder == 'down'" class="material-icons-outlined">arrow_downward</span>
                    <span v-else class="material-icons-outlined">unfold_more</span>
                  </th>
                  <th class="request-providers__table-th">Fat. Min.</th>

                  <th class="request-providers__table-th request-order-container" @click="toggleOrder">Participação
                    <span v-if="tableOrder == 'up'" class="material-icons-outlined">arrow_upward</span>
                    <span v-else-if="tableOrder == 'down'" class="material-icons-outlined">arrow_downward</span>
                    <span v-else class="material-icons-outlined">unfold_more</span>
                  </th>

                  <th class="request-providers__table-th">Observação</th>
                  <th class="request-providers__table-th">Status</th>
                  <th class="request-providers__table-th">Ações</th>
                </tr>
              </thead>
              <tbody class="request-providers__table-tbody">
                <tr v-for="(item, index) in filterProvider(providers)" 
                      :key="item.produto_id" 
                    class="request-providers__table-tbody-tr"
                    :class="getSpecialBackground(index)">
                  <td class="request-providers__table-td request-providers__table-th-provider">{{ item.seller.provider.name || '-' }}</td>
                  <td class="request-providers__table-td">{{ item.seller.name || '-'}} <br/> {{ item.seller.email || '-' }}</td>
                  <td class="request-providers__table-td"><cc-whatsapp :phone="item.seller.phone"/></td>
                  <td class="request-providers__table-td">{{ item.delivery ? item.delivery.form : '-' }} <br> {{ item.delivery ? formatDate(item.delivery.date) : '-' }}</td>
                  <td  class="request-providers__table-td">
                    {{ item.qtdCotacao || '--' }}
                  </td>
                  <td class="request-providers__table-td">{{ item.delivery ? item.delivery.minimum_billing.toLocaleString('pt-BR',{currency: 'BRL', style: 'currency', minimumFractionDigits: 2}) : '-' }}</td>
                  <!-- <td class="request-providers__table-td">{{ item.entrega ? item.entrega.observacao : '-' }}</td> -->
                  <td  class="request-providers__table-td">
                    <div v-if="item.seller_client && item.seller_client.participation" :class="getPercent(item.seller_client.participation)">{{(item.seller_client.participation * 100).toFixed(2) + ' %'}}</div>
                  </td>
                  <td class="request-providers__table-td">
                    <span v-if="item.delivery && item.delivery.obs" class="material-icons-outlined" :title="item.delivery.obs">info</span>
                  </td>
                  <td class="request-providers__table-td">
                    <div class="table-not-typed" v-if="item.status == 'NOT_TYPED'">Não digitada</div>
                    <div class="table-typed" v-if="item.status == 'TYPED'">Digitada</div>
                    <div class="table-freezed" v-if="item.status == 'FROZEN'">CONGELADA</div>
                    <div class="table-created" v-if="item.status == 'ORDERED'">Pedido Gerado</div>
                    <div class="table-nooffer" v-if="item.status == 'NOT_OFFERED'">SEM OFERTA</div>

                  </td>
                  <td class="request-providers__table-td" style="display: flex;">
                    <span @click="remove(item)" title="Remover da cotação" class="material-icons-outlined table-icon-delete">delete</span>
                    <span @click="open_participation(item)" title="Histórico da digitação" class="material-icons-outlined table-icon-list">list_alt</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <SellerParticipation v-else :selectedStatus="selectedStatus" :request_id="request_id" :request_seller="current_request_seller"
            />
        </div>
      </div>
    </div>
      <!-- <cc-modal :modal="modal" @close="close">
              <div slot="body" class="container-fluid">
                  <cc-loader v-show="loading" />
                  <div class="row" id="modalBodyPrint" v-show="!loading">
                      <div class="col-3 div-count">
                          <span class="span-count">Total de Fornecedores - ({{providers.length}})</span>
                      </div>
                      <div class="col-3">
                          <span class="span-participant">Fornecedores Engajados - ({{get_providers_participant()}})</span>
                      </div>
                      <table class="table shadow">
                          <thead>
                          <tr>
                              <th class="pl-3">FORNECEDOR</th>
                              <th>VENDEDOR</th>
                              <th>EMAIL</th>
                              <th>TELEFONE</th>
                              <th>COND. PGTO.	</th>
                              <th>DATA ENTREGA</th>
                              <th>FAT. MIN.</th>
                              <th>OBSERVAÇÃO</th>
                              <th class="text-center">STATUS</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="item in providers" v-bind:key="item.produto_id">
                              <td class="pl-3">{{ item.fornecedor_nome || '-' }}</td>
                              <td>{{ item.vendedor_nome || '-'}}</td>
                              <td>{{ item.vendedor_email || '-' }}</td>
                              <td><cc-whatsapp :phone="item.vendedor_telefone" /></td>
                              <td>{{ item.entrega ? item.entrega.forma : '-' }}</td>
                              <td>{{ item.entrega ? item.entrega.data_entrega : '-' }}</td>
                              <td>{{ item.entrega ? item.entrega.faturamento_minimo : '-' }}</td>
                              <td>{{ item.entrega ? item.entrega.observacao : '-' }}</td>
                              <td class="text-center">
                                  <cc-status :status="item.status"></cc-status>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
      </cc-modal> -->
  </template>
  <script>
  
      import QuotationService from "@/services/QuotationService";
      import RequestService from "@/services/v3/request.service";
      import SellerService from "@/services/v3/providers/seller.service";
      import { mapState } from 'vuex';
      import ErrorHandlerService from '@/services/ErrorHandlerService';
      import Pagination from "@/components/cliente/base-components/Pagination";
      import { loaderMixin } from '@/mixins/sweet-loader.mixin';
      import StandardInput from '@/components/ui/inputs/StandardInput.vue'
      import SellerParticipation from './components/seller-participation.partial.vue'
  
      export default {
          mixins: [ loaderMixin ],
          props: ["request_id", "closeDialog"],
          components: { SellerParticipation, StandardInput },
          computed: mapState(['statuses']),
          data() {
              return {
                  VIEW: 'LIST',
                  loading: false,
                  service: new QuotationService(),
                  requestService: new RequestService(),
                  seller_svc: new SellerService(),
                  providers: [],
                  current_request_seller: undefined,
                  tableOrder: undefined,
                  qtdOrder: undefined,
                  modal: {
                      title: `Fornecedores da Cotação #${this.request_id}`,
                      unpermitted_message: "Aceite o termo de consentimento para continuar.",
                      permitted: true,
                      cancel_text: "voltar",
                      nobackbutton:false,
                      style: {
                          width: "90%"
                      }
                  },
                  arraySelectStatus: [],
                  selectedStatus: undefined
              }
          },
          methods: {
            filterProvider(providers){

              if (this.selectedStatus) {
                const resp = []
                providers.forEach(item => {
                  if (item.status == 'NOT_TYPED' && this.selectedStatus == 7) resp.push(item)
                  else if(item.status == 'TYPED' && this.selectedStatus == 12) resp.push(item)
                  else if(item.status == 'FROZEN' && this.selectedStatus == 10) resp.push(item)
                  else if(item.status == 'ORDERED' && this.selectedStatus == 5) resp.push(item)
                  else if(item.status == 'NOT_OFFERED' && this.selectedStatus == 1) resp.push(item)
                })
                return resp
              } else return providers
            },
            toggleQtd(){
              if (this.qtdOrder != 'down') this.qtdOrder = 'down'
              else this.qtdOrder = 'up'
              if (this.qtdOrder == 'up') this.providers = this.providers.sort((a,b) => {
                if (a.qtdCotacao && b.qtdCotacao) return a.qtdCotacao - b.qtdCotacao
              })
              else if (this.qtdOrder == 'down') this.providers = this.providers.sort((a,b) => {
                if (a.qtdCotacao && b.qtdCotacao) return b.qtdCotacao - a.qtdCotacao
              })
              this.$forceUpdate()
            },
            toggleOrder () {
              if (this.tableOrder != 'down') this.tableOrder = 'down'
              else this.tableOrder = 'up'
              if (this.tableOrder == 'up') this.providers = this.providers.sort((a,b) => {
                if (a.seller_client && b.seller_client) return a.seller_client.participation - b.seller_client.participation
              })
              else if (this.tableOrder == 'down') this.providers = this.providers.sort((a,b) => {
                if (a.seller_client && b.seller_client) return b.seller_client.participation - a.seller_client.participation
              })
              this.$forceUpdate()
            },
            setStatusValue(value) { this.selectedStatus = value },
            getPercent(value){
              if(value <= 0.3) return 'table-not-typed'
              else if(value >= 0.7) return 'table-typed'
              else return 'percent-orange'
            },
              open_participation(seller) {
                  this.current_request_seller = seller
                  this.VIEW = 'PARTICIPATION'
              },
              formatDate (date) {return date.split('-')[2] +'/'+ date.split('-')[1] +'/'+ date.split('-')[0]},
              close() {
                  this.$emit('close');
              },
              remove(request_seller) {
                  this.confirm_action({
                    message: "Confirma remoção do vendedor?",
                    subtitle: "O vendedor será removido da cotação e do cliente!",
                    callback: () => {
                      this.present_loader("Removendo vendedor da cotação...")
                      return this.requestService.remove_seller(this.request_id, request_seller.seller.id)
                                    .then(() => this.dismiss_loader())
                                    .then(() => {
                                        this.present_loader("Removendo vendedor do cliente...")
                                        this.seller_svc.deactivate_client(request_seller.seller.id, request_seller.seller_client.client_id)
                                        .then(this.load_providers)
                                        .then(() => this.dismiss_loader())
                                    })
                    }
                  })
              },
              load_providers() {
                  this.loading = true;
                  return this.requestService.sellers(this.request_id).then((request) => {
                      this.loading = false;
                      this.providers = request.data
                  })
              },
              get_providers_participant(){
                  let status = ['NÃO DIGITADA','NÃO DIGITADO','AGUARDANDO CONFIRMACAO','NOT_TYPED'];
                  return this.providers.filter(p => !status.includes(p.status)).length;
              },
              getSpecialBackground(index) {
                if (index % 2 != 0) return 'page-table-line-special'
              },
          },
          created() {
              this.load_providers()
              this.statuses.forEach((el) => {
                this.arraySelectStatus.push({
                  text: el.name,
                  value: el.value
                })
              })
              this.arraySelectStatus.push({
                  text: "Sem oferta",
                  value: 1
                })
          }
      };
  </script>
  
  <style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
  }
  .modal-content-container{
    background-color: white;
    min-width: 50vw;
    border-radius: 12px;
  }
  .request-providers__main-container {
    width: 115vw;
    height: 100vh;
  }
  .request-providers__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
  }
  .request-providers__header-title {
    color: #FFFFFF;
    letter-spacing: -2%;
    font-weight: 500;
    font-size: 32px;
  }
  .request-providers__closebtn-icon {
    color: #FFFFFF;
    font-size: 30px;
    cursor: pointer;
  }
  .request-providers__providers-info__container {
    display: flex;
    align-items: center;
    margin: 20px;
  }
  .request-providers__total-providers__wrapper {
    background-color: var(--primary-color)1a;
    margin-right: 3vw;
    padding: 6px;
    border-radius: 8px;
  }
  .request-providers__total-providers__title {
    color: var(--primary-color);
    font-weight: 300;
    font-size: 20px;
  }
  .request-providers__total-entries{
  background-color: rgb(204, 227, 235);
  color: rgb(79, 79, 247);
  font-size: 20px;
  font-weight: 300;
  padding: 6px;
  border-radius: 8px;
  margin-left: 2vw;
}
  .request-providers__engaged-providers__wrapper {
    background-color: #2acd721a;
    padding: 6px;
    border-radius: 8px;
  }
  .request-providers__engaged-providers__title {
    color: #30aa4c;
    font-weight: 300;
    font-size: 20px;
  }
  .table-icon-delete{
    color: lightcoral;
    cursor: pointer;
  }
  .table-icon-list{
    color: var(--primary-color);
    cursor: pointer;
  }
  .table-not-typed{
    background-color: lightcoral;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  .percent-orange{
    background-color: lightsalmon;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  .table-typed{
    background-color: lightgreen;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
  }
  .table-freezed{
    background-color: gray;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
  }
  .table-created{
    background-color: lightblue;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
  }
  .table-nooffer{
    outline: 1px solid gray;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
  }
  .request-providers__table-container {
    margin: 20px;
    overflow-y: auto;
    max-height: 72vh;
  }
  .request-providers__table-wrapper {}
  .request-providers__table-thead {}
  .request-providers__table-thead-tr {
    background-color: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    color: #605F5F;
    font-size: 14px;
    font-weight: 400;
  }
  .request-providers__table-th {
    font-size: 14px;
    font-weight: 600;
  }
  table{
    th {
      border-bottom: none;
    }
  }
  .request-providers__table-tbody {}
  .request-providers__table-tbody-tr {}
  .request-providers__table-td {
    color: #605F5F;
    font-size: 14px;
    font-weight: 300;
  }
  .request-providers__table-th-provider{ width: 22%; }
  .request-providers__table-th-date{ width: 7%; }
  .request-providers__table-th-cond{width: 15%;}
  .page-table-line-special{
    background: #F7F7F7;
  }
  .request-order-container{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .request-providers-back-button{
    margin-left: auto;
    margin-right: 2vw;
    color: var(--primary-color);
    outline: var(--primary-color) 1px solid;
    padding: 0.5vh 1vw;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      background-color: var(--primary-color);
      color: white;
    }
  }
  @media only screen and (max-width: 750px) {
    .request-providers__header-title {
      font-size: 24px;
    }
    .request-providers__total-providers__title {
      font-size: 16px;
    }
    .request-providers__engaged-providers__title {
      font-size: 16px;
    }
    .request-providers__table-container {
      height: 70vh;
    }
  }
      @import "./request-providers.modal";
  </style>
