<style lang="scss" scoped>

.logo {
  width: 10rem;
}

.btn_send {
  padding: 10px 15px;
  background: #f39c12;
  border: 1px solid #f39c12;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.button_monitor {
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #e8e9ec;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.button_monitor i {
  color: #ec7f3b;
  font-size: 24px;
  margin-right: 10px;
}
.table {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
  thead {
    tr {
      th {
        text-transform: none;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 0.9rem;
        font-weight: 400;
        a {
          font-weight: 700;
        }
        span {
          border-radius: 25px;
          padding: 0 0 !important;
          font-size: 11px;
          font-weight: 700;
          height: 16px;
          &.em-digitacao {
            background-color: rgba(105, 228, 166, 0.2);
          }
          &.nao-digitada {
            background-color: rgba(246, 180, 0, 0.2);
          }
          &.pedido-gerado {
            background-color: rgba(27, 41, 168, 0.2);
          }
          &.cancelado {
            background-color: rgba(201, 17, 17, 0.2);
          }
        }
        .select {
          width: 100px;
          select {
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" id="products-modal">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div class="row" style="width: 100%;">
                <div class="col-3">
					<img class="logo" src="/images/logotipo.png"/>
				</div>
                <div class="col-sm-7" style="text-align: left;color: gray;">
                  <h4
                    style="padding-bottom: 9px;padding-right: 23px;text-align: left;color: gray;font-family:monospace;font-size: 25px;"
                  >Produtos da Lista: {{nameListMobile.join(",")}}</h4>
                </div>

                <div class="col-sm-2 not-print" style="text-align: left;color: gray;">
                  <button v-on:click="print()" class="button_monitor">
                    <span class="icon-icon_print" style="margin-right: 7px;"></span>
                    Imprimir
                  </button>
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-body" >
            <div class="row" id="modalBodyPrint">
              <div class="col-sm-10 only-print" style="text-align: left;color: gray;">
                  <h4
                    style="padding-right: 23px;text-align: left;color: gray;"
                  >Produtos da Cotação: {{listMobile}}</h4>
                </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>EAN</th>
                    <th>Embalagem</th>
                    <th>Categoria</th>
                    <th>Quantidade</th>
                    <th>Estoque</th>
                    <th>Lista</th>
                    <th>Loja</th>
                    <th>Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in products" v-bind:key="item.produto_id">
                    <td>{{ item.pro_descricao}}</td>
                    <td>{{ item.pro_ean}}</td>
                    <td>{{item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem}}</td>
                    <td>{{item.cat_descricao}}</td>
                    <td>{{item.quantidade}}</td>
                    <td>{{item.estoque || 0}}</td>
                    <td>{{item.lista_descricao}}</td>
                    <td>{{item.loj_descricao}}</td>
                    <td>{{item.usu_nome}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer not-print">
            <slot name="footer">
              <button class="btn_close_modal" type="button" @click="close()">Cancelar</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import QuotationService from "../../../services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";

export default {
  props: ["listMobile","nameListMobile"],
  data() {
    return {
      products: [],
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
	  },
    getProducts() {
      var quotationService = new QuotationService();
      quotationService
        .getProductsListMobile(this.listMobile)
        .then(response => response.data)
        .then(data => {
          this.products = data.produtos;
        })
        .catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
    },
    print() {
      setTimeout(() => {

		var conteudoModal = window.document.getElementById("products-modal").innerHTML;

		var conteudoImpressao =
			"<html>" +
			"<head>" +
			'<link rel="stylesheet" href="/css/app.css">' +
			'<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
			"<title>" +
			"</title>" +
            "<style>" +
            ".logo {width: 10rem;}" +
            "</style>" +
			"</head>" +
			"<body>" +
			conteudoModal +
			"</body>";

		let name = '_blank';
		let specs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'];
		let replace = true;

		specs = !!specs.length ? specs.join(',') : '';

        var telaImpressao = window.open("", '_blank', ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'], true);

		telaImpressao.document.write(conteudoImpressao);

		setTimeout(() => {
            telaImpressao.document.close();
            telaImpressao.focus();
            telaImpressao.print();
            telaImpressao.close();
          }, 1500);

        this.imprimindo = false;
	  }, 500);
	}
  },

  mounted() {
    this.getProducts();
  }
};
</script>
<style lang="scss" scoped>
@import '@/sass/commons/_variables';

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.control-label {
  color: $secondary-color;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
input {
  border-radius: 5px !important;
  margin-bottom: 5px;
}
.modal-container {
  width: 1100px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  overflow: auto;
  margin: 10px 0;
  max-height: 600px;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: rgba(243, 133, 5, 0.5);
}

.subtitle {
  color: #314b5f;
}
.btn_close_modal {
  padding: 10px 15px;
  background: transparent;
  border: 1px solid #f39c12;
  color: gray;
  border-radius: 5px;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dropzone.dz-clickable {
  border-radius: 8px;
}
</style>
