<template>
    <div v-if="selectedCot" class="edit-tab-container">
        <div class="edit-tab-inputs-container">
            <div>
                <p class="products-page__datetime-title">Cotação ID</p>
                <input  type="number" v-model="selectedCot.cli_id" disabled class="edit-tab-input">
            </div>
            <div>
                <p class="products-page__datetime-title">Vendedores convidados</p>
                <input  type="number" v-model="selectedCot.vend_convidados" class="edit-tab-input">
            </div>

            <div>
                <p class="products-page__datetime-title">Vendedores engajados</p>
                <input  type="number" v-model="selectedCot.vend_engajados" class="edit-tab-input">
            </div>
            <div>
                <p class="products-page__datetime-title">Vendedores pedido</p>
                <input  type="number" v-model="selectedCot.vend_pedido" class="edit-tab-input">
            </div>
            <div>
                <p class="products-page__datetime-title">Produtos cotação</p>
                <input  type="number" v-model="selectedCot.prod_cotacao" class="edit-tab-input">
            </div>
            <div>
                <p class="products-page__datetime-title">Produtos comprados</p>
                <input type="number" v-model="selectedCot.prod_comprado" class="edit-tab-input ">
            </div>
        </div>
        <div class="edit-tab-buttons-container">
            <StandardButton text="voltar" :action="()=>selectedCot=undefined"/>
            <StandardButton text="Salvar" :action="save"/>
        </div>
    </div>
    <div v-else class="edit-tab-container">
        <div class="page-table-header">
            <div class="page-table-header-text envelope-width"></div>
            <div class="page-table-header-text name-width">Cotação</div>
            <div class="page-table-header-text name-width">Vend convidados</div>
            <div class="page-table-header-text name-width">Vend engajados</div>
            <div class="page-table-header-text name-width">Vend pedidos</div>
            <div class="page-table-header-text name-width">Prod cotação</div>
            <div class="page-table-header-text name-width">Prod comprados</div>
            <div class="page-table-header-text envelope-width"></div>
        </div>
        <div v-for="value in cots" :key="value.id" class="page-table-row">
            <div class="page-table-header-text envelope-width"></div>
            <div class="page-table-header-text name-width">{{ value.cot_id }}</div>
            <div class="page-table-header-text name-width">{{ value.vend_convidados }}</div>
            <div class="page-table-header-text name-width">{{ value.vend_engajados }}</div>
            <div class="page-table-header-text name-width">{{ value.vend_pedido }}</div>
            <div class="page-table-header-text name-width">{{ value.prod_cotacao }}</div>  
            <div class="page-table-header-text name-width">{{ value.prod_comprado }}</div>
            <div class="page-table-header-text envelope-width">
                <span @click="selectedCot=value" class="material-icons-outlined" style="color: lightblue; cursor: pointer;">edit</span>
            </div>
        </div>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import ClientService from '@/services/v3/client.service.js';

    export default {
        name: 'EditCotTabComponent',
        props: ['client','setLoading'],
        components: {
            StandardButton
        },
        data() {
            return {
                selectedCot: undefined,
                svc: new ClientService(),
                cots: []
            }
        },
        mounted () {
            this.setLoading(true)
            this.svc.getCotList(this.client.id).then(resp => {
                this.cots = resp.data.data
                this.setLoading(false)
            })
        },
        methods: {
            save() {
                this.setLoading(true)
                this.svc.updateCot(this.selectedCot).then(resp => {
                    this.setLoading(false)
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
.edit-tab-container{
    margin: 2em;
    height: 75vh;
    overflow: auto;
}
.products-page__datetime-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.form-control {
  border-radius: 8px !important;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.edit-tab-inputs-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1vh 2vw;
}
.edit-tab-input{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 8px !important;
}
.edit-tab-input:focus{
    outline: none;
}
.edit-tab-buttons-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 5vh;
    gap: 5vw;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
  margin-bottom: 3vw;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
  padding: 1vw 0;
  border-bottom: 1px solid lightgray;
}
.envelope-width {
  width: 5%;
}
.name-width {
  width: 15%;
}
</style>