<style lang="scss" scoped>
    @import "@/sass/commons/_variables";
</style>

<template>

	<div class="tab-pane fade show active in" id="orders" role="tabpanel" aria-labelledby="order-tab">
         <cc-mobile-note class="mt-3 mb-3" />
        <!-- <div class="tabs d-flex align-middle w-100 justify-content-center mb-4 pt-0">
            <ul class="nav nav-tabs justify-content-center" role="tablist">
                <li :key="1" class="nav-item" :class="{'active': tab == 'REQUEST_ORDER' }" @click="tab = 'REQUEST_ORDER'">
                    <a class="nav-link">cotação</a>
                </li>
                <li :key="2"  class="nav-item" :class="{'active': tab == 'DIRECT_PURCHASE' }" @click="tab = 'DIRECT_PURCHASE'">
                    <a class="nav-link">compra direta</a>
                </li>
            </ul>
        </div> -->
        <cc-request-orders-subtab v-if="$store.state.tab == 'REQUEST_ORDER'" :request_id="request_id" />
        <cc-direct-orders-subtab v-if="$store.state.tab == 'DIRECT_PURCHASE'" />

  	</div>

</template>
<script>

import OrderService from "@/services/OrderService";
import * as CONSTANTS from "@/constants/constants";
import DirectOrdersSubTab from './_direct_orders.subtab'
import RequestOrdersSubTab from './_request_orders.subtab'

export default {
    props: {
        request_id: {
            type: Number
        }
    },
    components: {
        'cc-direct-orders-subtab' : DirectOrdersSubTab,
        'cc-request-orders-subtab' : RequestOrdersSubTab
    },
    data() {
        return {
            page: 1,
            showPagination: false,
            orders: [],
            CONSTANTS: CONSTANTS,
            items_by_page_value: 5,
            service: new OrderService(),
            is_loading: true
        };
    },
    methods: {
        getTotal(order) {
            return order.itens.reduce((x, { preco_embalagem, quantidade }) => x + (preco_embalagem*quantidade), 0);
        },
        translateStatus(status) {
            switch(status) {
                case "AGUARDANDO_CONFIRMACAO":
                    return "AGUARDANDO CONFIRMACAO";
                case "CONFIRMADO":
                    return "CONFIRMADO";
                default:
                    return "Não identificado!"
            }
        },
        paginate() {
            this.page = this.page + 1;
            this.getDirectSaleOrders();
        },
        getDirectSaleOrders() {
            this.is_loading = true;
            this.service.getDirectSaleOrders({ page: this.page, page_size: this.items_by_page_value })
            .then(response => response.data)
            .then(data => {
                this.is_loading = false;
                this.orders = this.orders.concat(data.data);
                this.showPagination = data.next_page_url != null;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });;
        }
    },
    watch: {
        items_by_page_value: function() {
            this.orders = [];
            this.getDirectSaleOrders();
        }

    },
    mounted() {
        this.getDirectSaleOrders();
    }
};
</script>
