<style lang="scss" scoped>
	@import './change-store.modal';
</style>
<template>
    <cc-modal :modal="modal" @close="close">
        <div slot="body" class="row">
            <cc-loader-full v-show="loading" :text="'Aguarde... Essa operação pode levar alguns minutos.'" />
            <div class="col-sm-4">
                <label for="inputCodigo" class="col-sm-12 control-label">Empresa de Origem</label>
				<multiselect :disabled="true" v-model="storeOrigin" track-by="value" placeholder="" label="name" open-direction="below" selected-label="Selecionado" :options="listStores" :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
            <div class="col-sm-4 change">
                <i class="fas fa-long-arrow-alt-right change-icon"></i>
            </div>
            <div class="col-sm-4">
                <label for="inputCodigo" class="col-sm-12 control-label">Empresa de Destino</label>
				<multiselect v-model="storeDestiny" track-by="value" placeholder="" label="name" open-direction="below" selected-label="Selecionado" :options="listStores" :searchable="true" :allow-empty="false" :show-pointer="true" :show-labels="false"/>
            </div>
        </div>
    </cc-modal>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import RequestsService from "@/services/v2/requests.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default {
    props: ["ressuply"],
    data() {

        return {
            modal: {
                title: "Trocar Empresa",
                permitted: true,
                submit: this.changeStore,
                submit_text: 'Enviar',
                subtitle: 'Aqui você pode trocar a empresa da sua requisição',
                icon_title: 'fas fa-exchange-alt',
                style: {
                    width: "70%"
                }
            },
            loading: false,
            listStores: [],
            storeOrigin: {name: this.ressuply.nomeLoja, value: this.ressuply.loj_id},
            storeDestiny: null,
            service: new RequestsService(),
        };
    },
    methods: {
            close() {
                this.$emit("close");
            },
            getStores() {
                var quotationService = new QuotationService();
                quotationService
                    .getStoresClient()
                    .then(response => response.data)
                    .then(data => {
                        this.listStores = data.requisitantes.map(store => {
                            return {
                                value: store.loj_id,
                                name: store.loj_descricao
                            };
                        });
                    })
                    .catch(error => {
                        ErrorHandlerService.handle(error, this.$store);
                    });
            },
            changeStore(){
                this.loading = true;
                let data = {
                    store_id : this.storeDestiny.value
                }
                this.service
                    .store_request(this.ressuply.numeroCotacao,data)
                    .then(response => response.data)
                    .then(data => {
                        this.loading = false;
                        this.close();
                        this.$emit("finish");
                    })
                    .catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store);
                    });

            }
        },
        mounted() {
            this.getStores()
        }
    };
</script>


